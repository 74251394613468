import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import ImageUploading from "react-images-uploading";
import { CAQTEGORIES, STORES } from "../../constants/url";
import { Alert, AlertTitle, CircularProgress, Snackbar } from "@mui/material";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import axios from "axios";
import CategorySection from "../CategorySection";
import { Sidebar } from "primereact/sidebar";
import { useRef } from "react";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
const AddStore = ({ reload, setReload }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [openTime, setOpenTime] = "";
  const [closeTime, setCloseTime] = useState("");
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [nameKu, setNameKu] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descAr, setDescAr] = useState("");
  const [descKu, setDescKu] = useState("");
  const [number, setNumber] = useState("");
  const [phone, setPhone] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [location, setLocation] = useState("");
  const [addressEn, setAddressEn] = useState("");
  const [addressAr, setAddressAr] = useState("");
  const [addressKu, setAddressKu] = useState("");
  const [category_section, setcategory_section] = useState("");
  const [open, setOpen] = useState("");
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState([]);
  const [checked1, setChecked1] = useState(0);
  const [cost, setCost] = useState("");
  const [message, setMessage] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [error, setError] = useState(false);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const onChangeLogo = (imageList, addUpdateIndex) => {
    setLogo(imageList);
  };
  const handleChange1 = (event) => {
    setChecked1(event.target.checked ? 1 : 0);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage(false);
  };
  const inputRefs = useRef([]);
  const inputRefs2 = useRef([]);
  const handleOpenTime = (index) => {
    const inputValue = inputRefs.current[index].value;
    console.log(`Input ${index} changed:`, inputValue);
  };
  const handleCloseTime = (index) => {
    const inputValue = inputRefs2.current[index].value;
    console.log(`Input ${index} changed:`, inputValue);
  };

  const AddCategorySubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name[en]", nameEn);
    formData.append("name[ar]", nameAr);
    formData.append("name[ku]", nameKu);
    formData.append("Description[en]", descEn);
    formData.append("Description[ar]", descAr);
    formData.append("Description[ku]", descKu);
    formData.append("category_id", category_section);
    formData.append("whatsapp_number", number);
    formData.append("facebook_page_link", link1);
    formData.append("tiktok_page_link", link2);
    formData.append("location", location);
    formData.append("address[en]", addressEn);
    formData.append("address[ar]", addressAr);
    formData.append("address[ku]", addressKu);
    formData.append("phone_number", phone);
    formData.append("possibility_of_delivery", checked1);
    if (checked1 === 1) {
      formData.append("delivery_cost", cost);
    }
    formData.append("delivery_cost", cost);
    if (open === "1") {
      formData.append("always_open", open);
    } else {
      formData.append("always_open", open);
      days.map((item, index) => {
        formData.append(`openCloseTimes[${index}][day]`, days[index]);
        formData.append(
          `openCloseTimes[${index}][open_time]`,
          inputRefs.current[index].value
        );
        formData.append(
          `openCloseTimes[${index}][close_time]`,
          inputRefs2.current[index].value
        );
      });
    }

    if (images.length != 0) {
      formData.append("image", images[0].file);
    }
    if (images.length != 0) {
      formData.append("logo", logo[0].file);
    }
    axios
      .post(STORES, formData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setVisible(false);
        setError(false);
      })

      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("token");
        }
        setError(true);
        showErrorAlert(error.response.data.errors);
        console.log(error.response.data.errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerContent = (
    <div>
      <Button
        label="إغلاق"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      {loading ? (
        <Button className=" p-button-text custom-btn2">
          <CircularProgress style={{ color: "#fff" }} size={30} />
        </Button>
      ) : (
        <Button
          label="حفظ"
          icon="pi pi-check"
          onClick={AddCategorySubmit}
          className="p-button-text custom-btn2"
        />
      )}
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Button
        label="متجر جديد"
        icon="pi pi-plus"
        onClick={() => setVisible(true)}
        className="custom-btn"
      />

      <Dialog
        header="Store Details"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        {error ? (
          <Alert severity="error" className="mb-4">
            <AlertTitle>خطأ</AlertTitle>

            <p>{erroralert.category_id}</p>
            <p>{erroralert.facebook_page_link}</p>
            <p>{erroralert.tiktok_page_link}</p>
            <p> {erroralert.whatsapp_number}</p>
            <p>{erroralert.image}</p>
            <p> {erroralert.logo}</p>
            <p>{erroralert.location}</p>
            <p>{erroralert.phone_number}</p>
          </Alert>
        ) : (
          ""
        )}

        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">الاسم باللغة الانكليزية</label>
          <InputText
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">الاسم باللغة العربية</label>
          <InputText
            value={nameAr}
            onChange={(e) => setNameAr(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">الاسم باللغة الكردية </label>
          <InputText
            value={nameKu}
            onChange={(e) => setNameKu(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">الوصف بالانكليزية</label>
          <InputTextarea
            autoResize
            value={descEn}
            onChange={(e) => setDescEn(e.target.value)}
            className="w-full mb-2"
            rows={5}
            cols={30}
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">الوصف بالعربية</label>
          <InputTextarea
            autoResize
            value={descAr}
            onChange={(e) => setDescAr(e.target.value)}
            className="w-full mb-2"
            rows={5}
            cols={30}
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">الوصف بالكردية </label>
          <InputTextarea
            autoResize
            value={descKu}
            onChange={(e) => setDescKu(e.target.value)}
            className="w-full mb-2"
            rows={5}
            cols={30}
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">اختار فئة</label>
          <CategorySection
            category_section={category_section}
            setcategory_section={setcategory_section}
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">رقم الهاتف</label>
          <InputText
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="w-full mb-2"
            type="number"
          />
        </div>

        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username"> رقم الـ Whatsapp</label>
          <InputText
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            className="w-full mb-2"
            type="number"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username"> رابط الـ Facebook</label>
          <InputText
            value={link1}
            onChange={(e) => setLink1(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username"> رابط الـ Tiktok</label>
          <InputText
            value={link2}
            onChange={(e) => setLink2(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">العنوان</label>
          <InputText
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">العنوان بالانكليزية</label>
          <InputText
            value={addressEn}
            onChange={(e) => setAddressEn(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">العنوان بالعربية</label>
          <InputText
            value={addressAr}
            onChange={(e) => setAddressAr(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">العنوان بالكردية</label>
          <InputText
            value={addressKu}
            onChange={(e) => setAddressKu(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column mb-2">
          <label htmlFor="username">وقت الفتح</label>
          <div className="d-flex flex-wrap mt-2">
            <div className="flex align-items-center mb-2">
              <RadioButton
                inputId="always-open"
                name="time"
                value="1"
                onChange={(e) => setOpen(e.value)}
                checked={open === "1"}
              />
              <label className="ms-2">مفتوح دوماً</label>
            </div>
            <div className="flex align-items-center mb-2 ms-4">
              <RadioButton
                inputId="ingredient2"
                name="time"
                value="0"
                onChange={(e) => setOpen(e.value)}
                checked={open === "0"}
              />
              <label className="ms-2">مغلق</label>
            </div>
          </div>
        </div>
        {open === "0" && (
          <div className="card flex justify-content-center">
            <Sidebar
              visible={visible2}
              position="right"
              onHide={() => setVisible2(false)}
            >
              <h4>Store Open Times</h4>
              <p>Please add time with foramt (24hour:minutes )</p>
              {days.map((item, index) => (
                <div className=" row mb-3" key={index}>
                  <div
                    className="col-sm-12 mb-2 text-capitalize fw-bold"
                    key={index}
                  >
                    {" "}
                    {item}:
                  </div>
                  <div className="col-sm-6">
                    <InputText
                      key={index}
                      ref={(el) => (inputRefs.current[index] = el)}
                      onChange={(e) => handleOpenTime(index)}
                      className="w-full "
                      placeholder="open time"
                    />
                  </div>
                  <div className="col-sm-6">
                    <InputText
                      key={index}
                      ref={(el) => (inputRefs2.current[index] = el)}
                      onChange={(e) => handleCloseTime(index)}
                      className="w-full "
                      placeholder="close time"
                    />
                  </div>
                </div>
              ))}
            </Sidebar>
            <Button
              label="Please add open time & close time"
              className="custom-btn2 "
              onClick={() => setVisible2(true)}
            />
          </div>
        )}
        <div className="d-flex gap-2 my-3">
          <label htmlFor="username">إمكانية التوصيل</label>
          <Checkbox
            className="p-0"
            checked={checked1}
            onChange={handleChange1}
          />
        </div>
        {checked1 === 1 && (
          <div className="d-flex flex flex-column gap-2">
            <label htmlFor="username">تكلفة التوصيل</label>
            <InputText
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              className="w-full mb-2"
            />
          </div>
        )}
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">رفع صورة</label>
          <ImageUploading
            value={images}
            onChange={onChange}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <div className="upload__image-wrapper position-relative mb-3">
                <Button
                  label="Choose"
                  icon="pi pi-plus"
                  className="btn-add w-100 d-block  py-2"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                  type="button"
                />
                {imageList.map((image, index) => (
                  <div
                    key={index}
                    className="image-item d-flex align-items-center"
                  >
                    <img src={image["data_url"]} alt="" width="100" />

                    <div className="image-item__btn-wrapper">
                      <Button
                        icon="pi pi-trash"
                        onClick={() => onImageRemove(index)}
                        className="p-button-text bg-danger text-white ms-3"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">رفع لوغو</label>
          <ImageUploading
            value={logo}
            onChange={onChangeLogo}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <div className="upload__image-wrapper position-relative mb-3">
                <Button
                  label="Choose"
                  icon="pi pi-plus"
                  className="btn-add w-100 d-block  py-2"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                  type="button"
                />
                {imageList.map((image, index) => (
                  <div
                    key={index}
                    className="image-item d-flex align-items-center"
                  >
                    <img src={image["data_url"]} alt="" width="100" />

                    <div className="image-item__btn-wrapper">
                      <Button
                        icon="pi pi-trash"
                        onClick={() => onImageRemove(index)}
                        className="p-button-text bg-danger text-white ms-3"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
      </Dialog>
      <Snackbar
        open={message}
        autoHideDuration={3000}
        onClose={handleCloseMessage}
        className="ic"
      >
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: "100%" }}
          style={{ background: "rgb(46, 125, 50)", color: "#fff" }}
        >
نجاح! تمت إضافة المعلومات بنجاح        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddStore;
