import { createContext, useEffect } from "react";
import useStateRef from "react-usestateref";

const LangContext = createContext({
    langs: [],
    get_langs: () => { },
    langState: '',
    set_lang: () => { },
});
export function LangContextProvider(props) {
    const [lang, setLang, langRef] = useStateRef("en");
    const [langs, setLangs, langsRef] = useStateRef([]);
    const handleChange = (event) => {
        setLang(event.target.value);
    };
    // useEffect(() => {
    //     axios.get(LANGUAGE).then((res) => {
    //         setLangs(res.data.data);
    //     });
    // }, []);

    const context = {
        langs: langsRef.current,
        langState: langRef.current,
        set_lang: handleChange
    };

    return (
        <LangContext.Provider value={context}>
            {props.children}
        </LangContext.Provider>
    );
}

export default LangContext;