import {
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { StyledNavItemIcon } from "./styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { NavItem } from "./NavSection";

const NestedLinkItem = ({ item }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{ py: 1, px: 0, color: "#fff" }}
      >
        <StyledNavItemIcon
          sx={{
            "& .MuiListItemIcon-root": {
              minWidth: "35px",
            },
          }}
        >
          {item.icon && item.icon}
        </StyledNavItemIcon>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ width: "100%" }}
        >
          <ListItemText
            disableTypography
            primary={item.title}
            sx={{ fontSize: "0.9rem", fontWeight: "800" }}
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </Stack>
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          {item.nestedLinks.map((nested, index) => (
            <NavItem key={index} item={nested}></NavItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default NestedLinkItem;
