import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import { useContext, useState } from "react";
import LangContext from "../context/LangContext";

const LangSelect = () => {
    const langsContext = useContext(LangContext);
    console.log(langsContext.langState)
    return (
        <FormControl
            variant="filled"
            sx={{ minWidth: 120 }}
            size="small"
            className="lang-select"
        >
            <InputLabel id="demo-simple-select-filled-label">Language</InputLabel>
            <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={langsContext.langState}
                onChange={langsContext.set_lang}
            >

                <MenuItem value="en" >
                    English
                </MenuItem>
                <MenuItem value="ar" >
                    Arabic
                </MenuItem>
                <MenuItem value="ku" >
                    Kurdish
                </MenuItem>
            </Select>
        </FormControl>
    )
}

export default LangSelect