import { useState, useEffect } from 'react';
import axios from 'axios';
import { InputLabel, MenuItem, FormControl, Select, Skeleton } from '@mui/material';
import { CAQTEGORIES } from '../constants/url';
const CategorySection = (props) => {
  const token = localStorage.getItem('token');
  const [info, setInfo] = useState([]);
  const [infoLoaded, setInfoLoaded] = useState(false);

  const handleChange = (e) => {
    props.setcategory_section(e.target.value);
  };
  const FetchJoinCategory = async () => {
    await axios
      .get(CAQTEGORIES, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {
        // if (error.status === 401) {
        //   navigate('/login');
        //   localStorage.removeItem('token');
        // }
      })
      .finally(() => {
        setInfoLoaded(true);
      });

 
  };
  useEffect(() => {
    FetchJoinCategory();
  }, []);
  return !infoLoaded ? (
    <Skeleton width={'100%'} height={'5rem'} />
  ) : (
    <FormControl  fullWidth className="mb-2">
     
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={props.category_section}
        onChange={handleChange}
        name="category_section"
      >
        {info.length > 0 ? (
          info.map((item) => (
            <MenuItem value={item.id} key={item.id}>
              {item.translations.name.en}
            </MenuItem>
          ))
        ) : (
          <MenuItem>No Category</MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default CategorySection;