import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { CREATE_POSTS } from '../../constants/url';
import { InputTextarea } from "primereact/inputtextarea";

const UpdatePost = ({ reload, setReload, i, id }) => {
    const [visible, setVisible] = useState(false);
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [text, setText] = useState(i.text);
    const [message, setMessage] = useState(false);
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };
    const AddCategorySubmit = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("text", text);

        axios
            .post(`${CREATE_POSTS}/${id}?_method=PUT`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {

                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);


            })

            .catch((error) => {

            })
            .finally(() => {
                setLoading(false);
            });
    };
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={AddCategorySubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );
    return (
        <>
            <Button icon="pi pi-pencil " onClick={() => setVisible(true)}
                className="p-button-success border-0 p-button-icon-only" />
            <Dialog header="Post Details" visible={visible} style={{ width: '50vw' }}
                onHide={() => setVisible(false)} footer={footerContent}>

                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">محتوى</label>
                    <InputTextarea autoResize value={text} onChange={(e) => setText(e.target.value)}
                        className="w-full mb-2" rows={5} cols={30} />
                </div>


            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
                    نجاح! تم تحديث المعلومات بنجاح
                </Alert>
            </Snackbar>
        </>
    )
}

export default UpdatePost