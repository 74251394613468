import React, { useState, useEffect } from 'react';
import {
    Container,
    Grid,
    Pagination,
    Paper,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { SELLERS } from '../../constants/url';
import StoreBySeller from './StoreBySeller';

const SellersPage = () => {
    const token = localStorage.getItem('token');
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPage] = useState(1);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const FetchUser = async () => {
        setLoading(true);
        await axios
            .get(`${SELLERS}?page=${page}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data.data.data);
                    setTotalPage(response.data.data.last_page);
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            });

        setLoading(false);
    };

    useEffect(() => {
        FetchUser();
    }, [reload, page]);

    return (
        <>
            <Helmet>
                <title>البائعين | Business Iraq</title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        البائعين
                    </Typography>
                </Stack>
                {loading ? <Skeleton animation="wave" height={500} variant="rectangular" /> :
                    <>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">رقم البائع التعريفي</TableCell>
                                        <TableCell align="center">اسم</TableCell>
                                        <TableCell align="center">رقم التليفون</TableCell>
                                        <TableCell align="center">المتجر المملوك</TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {info.length > 0 ? info.map((item) => (
                                        <TableRow
                                            key={item.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{item.id}</TableCell>
                                            <TableCell align="center">{item.name}</TableCell>
                                            <TableCell align="center">{item.phone_number}</TableCell>
                                            <TableCell align="center">
                                                <StoreBySeller id={item.id} i={item} />
                                            </TableCell>
                                        </TableRow>
                                    )) : <Typography variant="h6" className='text-center' sx={{ mb: 5 }}> لا بائعين </Typography>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                }
                <Stack spacing={2} sx={{ padding: 2 }}>
                    <Pagination
                        page={page}
                        onChange={handleChangePage}
                        count={totalPages}
                        variant="outlined"
                        shape="rounded"
                        sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}
                    />
                </Stack>
            </Container>
        </>
    );
}

export default SellersPage;
