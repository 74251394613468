import axios from "axios";
import { useState } from "react";
import { CAQTEGORIES, STORAGE } from "../../constants/url";
import { useEffect } from "react";
import {
  Container,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import AddCategory from "./AddCategory";
import DeleteCategory from "./DeleteCategory";
import UpdateCategory from "./UpdateCategory";
import { useContext } from "react";
import LangContext from "../../context/LangContext";

const CategoryPage = () => {
  const langsContext = useContext(LangContext);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const FetchJoinCategory = async () => {
    setLoading(true);
    await axios
      .get(CAQTEGORIES, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {
        // if (error.status === 401) {
        //   navigate('/login');
        //   localStorage.removeItem('token');
        // }
      });

    setLoading(false);
  };
  useEffect(() => {
    FetchJoinCategory();
  }, [reload]);
  const skeleton = (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ padding: "0 5px", marginY: "1rem", marginX: "1rem" }}
      >
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={150}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ padding: "0 5px", marginY: "1rem", marginX: "1rem" }}
      >
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={150}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ padding: "0 5px", marginY: "1rem", marginX: "1rem" }}
      >
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={150}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ padding: "0 5px", marginY: "1rem", marginX: "1rem" }}
      >
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={150}
        />
      </Grid>
    </>
  );
  return (
    <>
      <Helmet>
        <title> الفئات | Business Iraq </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            الفئات
          </Typography>
          <AddCategory setReload={setReload} reload={reload} />
        </Stack>
        <div className="card">
          {loading ? (
            skeleton
          ) : (
            <>
              <div className="container">
                {info
                  ? info.map((item) => (
                      <div className="box-card" key={item.id}>
                        <div className="row align-items-center">
                          <div className="col-8 d-flex align-items-center">
                            <img src={`${STORAGE}${item.image}`} />
                            <h5 className="fw-bold ps-4">
                              {langsContext.langState === "en"
                                ? item.translations.name.en
                                : ""}
                              {langsContext.langState === "ar"
                                ? item.translations.name.ar
                                : ""}
                              {langsContext.langState === "ku"
                                ? item.translations.name.ku
                                : ""}
                            </h5>
                          </div>
                          <div className="col-4 text-end">
                            <UpdateCategory
                              i={item}
                              id={item.id}
                              setReload={setReload}
                              reload={reload}
                            />
                            <DeleteCategory
                              id={item.id}
                              setReload={setReload}
                              reload={reload}
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  : ""}
              </div>
            </>
          )}
        </div>
      </Container>
    </>
  );
};

export default CategoryPage;
