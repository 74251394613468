import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { CONTACT } from "../../constants/url";

const UpdateContact = ({ reload, setReload, i }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contact_email, setContact_email] = useState(i);
    const [message, setMessage] = useState(false);

    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };
    const UpdatePolicySubmit = () => {
        setLoading(true);

        const data = {
            contact_email: contact_email
        }
        axios
            .post(`${CONTACT}?_method=PUT`, data, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {

                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);


            })

            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={UpdatePolicySubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );
    return (
        <>
            <Button label="Update Contact Email" icon="pi pi-pencil" onClick={() => setVisible(true)}
                className=" p-button-success mb-4 mx-4" style={{ width: 'max-content' }} />
            <Dialog header="البريد الإلكتروني" visible={visible} style={{ width: '50vw' }}
                onHide={() => setVisible(false)} footer={footerContent}>

                <div className="d-flex flex flex-column gap-2">
                <InputText value={contact_email} onChange={(e) => setContact_email(e.target.value)}
                        className="w-full mb-2" />
                   
                </div>


            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
                    النجاح! المعلومات المحدثة بنجاح
                </Alert>
            </Snackbar>
        </>
    )
}

export default UpdateContact