import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import ImageUploading from 'react-images-uploading';
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { SLIDER, STORAGE } from '../../constants/url';

const UpdateSlider = ({ reload, setReload, i, id }) => {
    const [visible, setVisible] = useState(false);
    const token = localStorage.getItem('token');
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState(i.URL);
    const [showImage, setshowImage] = useState(i.image);
    const [images, setImages] = useState([]);
    const [message, setMessage] = useState(false);
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };
    const AddCategorySubmit = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("URL", url);
        if (images.length != 0) {
            formData.append('image', images[0].file);
        }
        axios
            .post(`${SLIDER}/${id}?_method=PUT`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {

                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);


            })

            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={AddCategorySubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );
    return (
        <>
            <Button icon="pi pi-pencil " onClick={() => setVisible(true)}
                className="p-button-success border-0 p-button-icon-only" />
            <Dialog header="Slider Details" visible={visible} style={{ width: '50vw' }}
                onHide={() => setVisible(false)} footer={footerContent}>

                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">رابط السلايدر</label>
                    <InputText value={url} onChange={(e) => setUrl(e.target.value)}
                        className="w-full mb-2" />
                </div>

                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">تحميل الصور</label>
                    <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
                        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                            <div className="upload__image-wrapper position-relative mb-3">
                                <Button label="Choose" icon="pi pi-plus"
                                    className="btn-add w-100 d-block  py-2"
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    type="button" />
                                {imageList.length > 0 ? (
                                    imageList.map((image, index) => (
                                        <div key={index} className="image-item d-flex align-items-center">
                                            <img src={image['data_url']} alt="" width="100" />

                                            <div className="image-item__btn-wrapper">
                                                <Button icon="pi pi-trash" onClick={() => onImageRemove(index)}
                                                    className="p-button-text bg-danger text-white ms-3" />

                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <img src={`${STORAGE}${showImage}`} width="100%" />
                                )}
                            </div>
                        )}
                    </ImageUploading>
                </div>
            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
                    نجاح! تم تحديث المعلومات بنجاح
                </Alert>
            </Snackbar>
        </>
    )
}

export default UpdateSlider