// component

import Iconify from "../../../components/iconify";
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const navConfig = [
  {
    title: "لوحة التحكم",
    path: "/dashboard/app",
    icon: getIcon("bi:pie-chart-fill"),
  },
  {
    title: " الفئات",
    path: "/dashboard/categories",
    icon: getIcon("bxs:category"),
  },
  {
    title: " المتاجر",
    path: "/dashboard/stores",
    icon: getIcon("bx:store"),
  },
  {
    title: " المنتجات",
    path: "/dashboard/products",
    icon: getIcon("icon-park:ad-product"),
  },
  {
    title: " البائعين",
    path: "/dashboard/sellers",
    icon: getIcon("clarity:group-solid"),
  },
  {
    title: " السلايدر",
    path: "/dashboard/slider",
    icon: getIcon("icon-park-outline:multi-picture-carousel"),
  },
  {
    title: "المنشورات",
    path: "",
    icon: getIcon("material-symbols:post-outline"),
    hasChildren: true,
    nestedLinks: [
      {
        title: "منشورات الصفحات",
        path: "/dashboard/posts",
        icon: getIcon("carbon:dot-mark"),
        isChild: true,
      },
      {
        title: "صور المنشورات",
        path: "/dashboard/post-images",
        icon: getIcon("carbon:dot-mark"),
        isChild: true,
      },
    ],
  },
  {
    title: " الفيديوهات",
    path: "/dashboard/Videos",
    icon: getIcon("ri:video-fill"),
  },
  {
    title: " خطط الاشتراكات",
    path: "/dashboard/plans",
    icon: getIcon("wpf:renew-subscription"),
  },
  {
    title: " سياسة الخصوصية",
    path: "/dashboard/policy",
    icon: getIcon("dashicons:privacy"),
  },
  {
    title: " شروط",
    path: "/dashboard/terms",
    icon: getIcon("iconoir:privacy-policy"),
  },
  {
    title: " المستخدمين",
    path: "/dashboard/users",
    icon: getIcon("clarity:group-solid"),
  },
  {
    title: " البريد الالكتروني",
    path: "/dashboard/email",
    icon: getIcon("mi:email"),
  },
];

export default navConfig;
