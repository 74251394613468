import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { Alert, AlertTitle, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PLANS } from "../../constants/url";

const UpdatePlan = ({ reload, setReload, i, id }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [price, setPrice] = useState(i.price);
    const [period, setPeriod] = useState(i.period);
    const [products_number, setProducts_number] = useState(i.products_number);
    const [posts_number, setPosts_number] = useState(i.posts_number);
    const [videos_number, setVideos_number] = useState(i.videos_number);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };
    const UpdatePlanSubmit = () => {
        setLoading(true);
        const data = {
            price: price,
            period: period,
            products_number: products_number,
            posts_number: posts_number,
            videos_number: videos_number
        }

        axios
            .post(`${PLANS}/${id}?_method=PUT`, data, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {

                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);

            })

            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={UpdatePlanSubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );
    return (
        <>
            <Button icon="pi pi-pencil " onClick={() => setVisible(true)}
                className="p-button-success border-0 p-button-icon-only" />
            <Dialog header="Subscription Plan Details" visible={visible} style={{ width: '50vw' }}
                onHide={() => setVisible(false)} footer={footerContent}>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">سعر</label>
                    <InputText value={price} onChange={(e) => setPrice(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الفترة</label>
                    <InputText value={period} onChange={(e) => setPeriod(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">عدد المنتجات</label>
                    <InputText value={products_number} type="number" onChange={(e) => setProducts_number(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">عدد المنشورات</label>
                    <InputText value={posts_number} type="number" onChange={(e) => setPosts_number(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">عدد الفيديوهات</label>
                    <InputText value={videos_number} type="number" onChange={(e) => setVideos_number(e.target.value)}
                        className="w-full mb-2" />
                </div>
            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
                    نجاح! تم تحديث المعلومات بنجاح
                </Alert>
            </Snackbar>
        </>
    )
}

export default UpdatePlan