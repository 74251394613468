import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { STORAGE } from '../../constants/url';
import { useState } from 'react';
import { Typography } from '@mui/material';


const Modal = ({ i }) => {
    const [visible, setVisible] = useState(false);
    return (
        <>
            <Button severity="secondary" icon="pi pi-eye" onClick={() => setVisible(true)} id={i.id} />
            <Dialog header="Store Video" visible={visible}
                style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <video
                    className="VideoInput_video"
                    width="100%"
                    controls
                    src= {`${STORAGE}${i.video_path}`}
                />
            </Dialog>
        </>
    )
}

export default Modal