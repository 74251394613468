import { Outlet, Navigate, useLocation } from 'react-router-dom'

const PrivateRoutes = () => {
    const token = localStorage.getItem("token")
    const location = useLocation();
    return (
        token ? <Outlet/> : <Navigate to="/login" state={{ from: location }} replace/>
   
    )
}

export default PrivateRoutes