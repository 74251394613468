import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import ImageUploading from "react-images-uploading";
import { Alert, AlertTitle, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { InputTextarea } from "primereact/inputtextarea";
import Checkbox from "@mui/material/Checkbox";
import StoreSection from "../StoreSection";
import { PRODUCTS } from "../../constants/url";
import { useNavigate } from "react-router-dom";
const AddProduct = ({ reload, setReload }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [category_section, setcategory_section] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [nameKu, setNameKu] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descAr, setDescAr] = useState("");
  const [descKu, setDescKu] = useState("");
  const [price, setPrice] = useState("");
  const [SKU, setSKU] = useState("");
  const [checked1, setChecked1] = useState(0);
  const [checked2, setChecked2] = useState(0);
  const [images, setImages] = useState([]);
  const [images2, setImages2] = useState([]);
  const [message, setMessage] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [error, setError] = useState(false);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const onChange2 = (imageList, addUpdateIndex) => {
    setImages2(imageList);
  };
  const handleChange1 = (event) => {
    setChecked1(event.target.checked ? 1 : 0);
  };
  const handleChange2 = (event) => {
    setChecked2(event.target.checked ? 1 : 0);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage(false);
  };
  const AddCategorySubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name[en]", nameEn);
    formData.append("name[ar]", nameAr);
    formData.append("name[ku]", nameKu);
    formData.append("description[en]", descEn);
    formData.append("description[ar]", descAr);
    formData.append("description[ku]", descKu);
    formData.append("price", price);
    formData.append("warrantyReturningPolicy", checked1);
    formData.append("freeZone", checked2);
    formData.append("SKU", SKU);
    formData.append("store_id", category_section);
    if (images.length != 0) {
      formData.append("main_photo", images[0].file);
    }

    images2.map((item, index) =>
      formData.append(`images[${index}]`, item.file)
    );
    elements.map(
      (i, index) => (
        formData.append(`variations[${index}][size]`, i[0]),
        i[1].map((item, index2) =>
          formData.append(`variations[${index}][colors][${index2}]`, item)
        )
      )
    );
    axios
      .post(PRODUCTS, formData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setVisible(false);
        setError(false);
      })

      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("token");
        }
        setError(true);
        showErrorAlert(error.response.data.errors);
        console.log(error.response.data.errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const footerContent = (
    <div>
      <Button
        label="إغلاق"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      {loading ? (
        <Button className=" p-button-text custom-btn2">
          <CircularProgress style={{ color: "#fff" }} size={30} />
        </Button>
      ) : (
        <Button
          label="حفظ"
          icon="pi pi-check"
          onClick={AddCategorySubmit}
          className="p-button-text custom-btn2"
        />
      )}
    </div>
  );

  const [size, setSize] = useState("");
  const [colors, setColors] = useState([]);
  const [elements, setElements] = useState([]);
  function handleButtonClick() {
    setElements((prevElements) => [...prevElements, [size, colors]]);
    setSize("");
    setColors([]);
  }

  return (
    <div className="card flex justify-content-center">
      <Button
        label="New Product"
        icon="pi pi-plus"
        onClick={() => setVisible(true)}
        className="custom-btn"
      />
      <Dialog
        header="تفاصيل المنتج"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        {error ? (
          <Alert severity="error" className="mb-4">
            <AlertTitle>Error</AlertTitle>
            <p>{nameEn === "" ? "حقل الاسم باللغة الإنجليزية مطلوب." : ""}</p>
            <p>{nameAr === "" ? "حقل الاسم العربي مطلوب." : ""}</p>
            <p>{nameKu === "" ? "الاسم الحقل الكردي مطلوب." : ""}</p>

            <p>
              {descEn === ""
                ? "حقل الوصف باللغة الإنجليزية مطلوب."
                : ""}
            </p>
            <p>
              {descAr === "" ? "حقل الوصف العربي مطلوب." : ""}
            </p>
            <p>
              {descKu === ""
                ? "حقل الوصف الكردي مطلوب."
                : ""}
            </p>

            <p>{erroralert.SKU}</p>
            <p>{erroralert.images}</p>
            <p>{erroralert.main_photo}</p>
            <p> {erroralert.price}</p>
            <p>{erroralert.store_id}</p>
          </Alert>
        ) : (
          ""
        )}

        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">الاسم الانكليزية</label>
          <InputText
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">الاسم العربي</label>
          <InputText
            value={nameAr}
            onChange={(e) => setNameAr(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">اسم كردي </label>
          <InputText
            value={nameKu}
            onChange={(e) => setNameKu(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">الوصف الانكليزي</label>
          <InputTextarea
            autoResize
            value={descEn}
            onChange={(e) => setDescEn(e.target.value)}
            className="w-full mb-2"
            rows={5}
            cols={30}
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">وصف اللغة العربية</label>
          <InputTextarea
            autoResize
            value={descAr}
            onChange={(e) => setDescAr(e.target.value)}
            className="w-full mb-2"
            rows={5}
            cols={30}
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">وصف كردي </label>
          <InputTextarea
            autoResize
            value={descKu}
            onChange={(e) => setDescKu(e.target.value)}
            className="w-full mb-2"
            rows={5}
            cols={30}
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">سعر</label>
          <InputText
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full mb-2"
            type="number"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">SKU</label>
          <InputText
            value={SKU}
            onChange={(e) => setSKU(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex gap-2 my-3">
          <label htmlFor="username">سياسة إعادة الضمان</label>
          <Checkbox
            className="p-0"
            checked={checked1}
            onChange={handleChange1}
          />
        </div>
        <div className="d-flex gap-2 my-3">
          <label htmlFor="username">المنطقة الحرة</label>
          <Checkbox
            className="p-0"
            checked={checked2}
            onChange={handleChange2}
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">حدد المتجر</label>
          <StoreSection
            category_section={category_section}
            setcategory_section={setcategory_section}
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">حجم</label>
          <InputText
            value={size}
            onChange={(e) => setSize(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">أدخل الألوان مفصولة بفاصلة</label>
          <InputText
            value={colors.join(",")}
            onChange={(e) => setColors(e.target.value.split(","))}
            className="w-full mb-2"
          />
        </div>
        <Button
          label="أضف الحجم والألوان إلى المنتج "
          className="custom-btn2 w-100 py-2 mt-2 mb-3"
          onClick={handleButtonClick}
        />

        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">قم بتحميل الصورة الرئيسية</label>
          <ImageUploading
            value={images}
            onChange={onChange}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <div className="upload__image-wrapper position-relative mb-3">
                <Button
                  label="اختار"
                  icon="pi pi-plus"
                  className="btn-add w-100 d-block  py-2"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                  type="button"
                />
                {imageList.map((image, index) => (
                  <div
                    key={index}
                    className="image-item d-flex align-items-center"
                  >
                    <img src={image["data_url"]} alt="" width="100" />

                    <div className="image-item__btn-wrapper">
                      <Button
                        icon="pi pi-trash"
                        onClick={() => onImageRemove(index)}
                        className="p-button-text bg-danger text-white ms-3 "
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">قم بتحميل صور المنتج</label>
          <ImageUploading
            multiple
            value={images2}
            onChange={onChange2}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <div className="upload__image-wrapper position-relative mb-3">
                <Button
                  label="اختار"
                  icon="pi pi-plus"
                  className="btn-add w-100 d-block  py-2"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                  type="button"
                />
                {imageList.map((image, index) => (
                  <div
                    key={index}
                    className="image-item d-flex align-items-center"
                  >
                    <img src={image["data_url"]} alt="" width="100" />

                    <div className="image-item__btn-wrapper">
                      <Button
                        icon="pi pi-trash"
                        onClick={() => onImageRemove(index)}
                        className="p-button-text bg-danger text-white ms-3"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
      </Dialog>
      <Snackbar
        open={message}
        autoHideDuration={3000}
        onClose={handleCloseMessage}
        className="ic"
      >
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: "100%" }}
          style={{ background: "rgb(46, 125, 50)", color: "#fff" }}
        >
          نجاح! تمت إضافة المعلومات بنجاح
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddProduct;
