import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography, Skeleton } from "@mui/material";
// components
import Iconify from "../components/iconify";
// sections
import { AppWidgetSummary } from "../sections/@dashboard/app";
import axios from "axios";
import { useEffect, useState } from "react";
import { STATICS } from "../constants/url";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const FetchStatics = async () => {
    setLoading(true);
    await axios
      .get(STATICS, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {});

    setLoading(false);
  };
  useEffect(() => {
    FetchStatics();
  }, []);
  const skeleton = (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={200}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={200}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={200}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <Skeleton
          variant="rounded"
          animation="wave"
          width="100%"
          height={200}
        />
      </Grid>
    </Grid>
  );
  return (
    <>
      <Helmet>
        <title> لوحة التحكم | بزنس العراق </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          مرحبا بعودتك
        </Typography>

        {loading ? (
          skeleton
        ) : (
          <Grid container spacing={3} className="app-total">
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="اجمالي مبيعات"
                total={info.total_sales}
                icon={"solar:money-bag-bold"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="المستخدمين"
                total={info.users}
                color="info"
                icon={"fa:users"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="المواد"
                total={info.items}
                color="warning"
                icon={"icon-park:ad-product"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="الطلبيات"
                total={info.orders}
                color="error"
                icon={"fluent-mdl2:reservation-orders"}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}
