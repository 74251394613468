import React, { useState, useEffect } from "react";
import {
  Container,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { PLANS } from "../../constants/url";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AddPlan from "./AddPlan";
import UpdatePlan from "./UpdatePlan";
import DeletePlan from "./DeletePlan";

const PlansPage = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const FetchPlans = async () => {
    setLoading(true);
    await axios
      .get(PLANS, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {});

    setLoading(false);
  };
  useEffect(() => {
    FetchPlans();
  }, [reload]);
  return (
    <>
      <Helmet>
        <title> خطط الاشتراك | Business Iraq </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            خطط الاشتراك
          </Typography>
          <AddPlan setReload={setReload} reload={reload} />
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">الرقم</TableCell>
                    <TableCell align="center">السعر</TableCell>
                    <TableCell align="center">الفترة</TableCell>
                    <TableCell align="center">عدد المنتجات</TableCell>
                    <TableCell align="center">عدد المنشورات</TableCell>
                    <TableCell align="center">عدد الفيديوهات</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {info.length != null ? (
                    info.map((item, index) => (
                      <TableRow
                        key={item.id}
                        id={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">{index}</TableCell>
                        <TableCell align="center">{item.price} </TableCell>
                        <TableCell align="center">{item.period} </TableCell>
                        <TableCell align="center">
                          {item.products_number}{" "}
                        </TableCell>
                        <TableCell align="center">
                          {item.posts_number}{" "}
                        </TableCell>
                        <TableCell align="center">
                          {item.videos_number}{" "}
                        </TableCell>
                        <TableCell
                          align="center"
                          className="d-grid justify-content-center"
                        >
                          <UpdatePlan
                            id={item.id}
                            i={item}
                            setReload={setReload}
                            reload={reload}
                          />
                          <DeletePlan
                            id={item.id}
                            setReload={setReload}
                            reload={reload}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Typography
                      variant="h6"
                      className="text-center"
                      sx={{ mb: 5 }}
                    >
                      {" "}
                      لا توجد خطط اشتراك{" "}
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default PlansPage;
