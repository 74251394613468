import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { PRODUCTS, STORAGE } from "../../constants/url";
import Modal from "./Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "primereact/button";
import AddProduct from "./AddProduct";
import DeleteProduct from "./DeleteProduct";
import UpdateProduct from "./UpdateProduct";
import LangContext from "../../context/LangContext";
import { useContext } from "react";
const ProductPage = () => {
  const token = localStorage.getItem("token");
  const langsContext = useContext(LangContext);

  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const FetchProducts = async () => {
    setLoading(true);
    await axios
      .get(PRODUCTS, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 500) {
          navigate("/login");
          localStorage.removeItem("token");
        }
      });

    setLoading(false);
  };
  useEffect(() => {
    FetchProducts();
  }, [reload]);
  return (
    <>
      <Helmet>
        <title> منتجات | Business Iraq </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            منتجات
          </Typography>
          <AddProduct setReload={setReload} reload={reload} />
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">الاسم</TableCell>
                    <TableCell align="center">الصورة الرئيسية</TableCell>
                    <TableCell align="center">المتجر</TableCell>
                    <TableCell align="center">الوصف</TableCell>
                    <TableCell align="center">السعر</TableCell>
                    <TableCell align="center">نسبة خصم</TableCell>
                    <TableCell align="center">سعر العرض</TableCell>
                    <TableCell align="center">مزيد من تفاصيل</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {info.length > 0 ? (
                    info.map((item) => (
                      <TableRow
                        key={item.id}
                        id={item.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {langsContext.langState === "en"
                            ? item.translations.name.en
                            : ""}
                          {langsContext.langState === "ar"
                            ? item.translations.name.ar
                            : ""}
                          {langsContext.langState === "ku"
                            ? item.translations.name.ku
                            : ""}
                        </TableCell>
                        <TableCell align="center">
                          {" "}
                          <img src={`${STORAGE}${item.main_photo}`} />
                        </TableCell>
                        <TableCell align="center">{item.store_name}</TableCell>
                        <TableCell align="center">
                          {langsContext.langState === "en"
                            ? item.translations.description.en
                            : ""}
                          {langsContext.langState === "ar"
                            ? item.translations.description.ar
                            : ""}
                          {langsContext.langState === "ku"
                            ? item.translations.description.ku
                            : ""}
                        </TableCell>
                        <TableCell align="center">{item.price}</TableCell>
                        <TableCell align="center">
                          {item.discountPercentage != null ? (
                            item.discountPercentage
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          {item.offerPrice != null ? (
                            item.offerPrice
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Modal id={item.id} i={item} />
                        </TableCell>
                        <TableCell align="center" className="d-grid">
                          <UpdateProduct
                            id={item.id}
                            i={item}
                            setReload={setReload}
                            reload={reload}
                          />
                          <DeleteProduct
                            id={item.id}
                            setReload={setReload}
                            reload={reload}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <Typography
                      variant="h6"
                      className="text-center"
                      sx={{ mb: 5 }}
                    >
                      {" "}
                      No Products{" "}
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default ProductPage;
