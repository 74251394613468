import axios from "axios";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import StoreSection from "../StoreSection";
import { POSTS } from "../../constants/url";
import {
  Container,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Modal from "./Modal";
import AddPost from "./AddPost";
import DeletePost from "./DeletePost";
import UpdatePost from "./UpdatePost";
import LangContext from "../../context/LangContext";

const PostPage = () => {
  const langsContext = useContext(LangContext);
  const [category_section, setcategory_section] = useState("");
  const [info, setInfo] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const FetchStorePost = async () => {
    setLoading(true);
    await axios
      .get(`${POSTS}${category_section}`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {});

    setLoading(false);
  };
  useEffect(() => {
    FetchStorePost();
  }, [reload, category_section]);
  return (
    <>
      <Helmet>
        <title> المنشورات | Business Iraq </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            منشورات المتاجر
          </Typography>
          <AddPost setReload={setReload} reload={reload} />
        </Stack>
        <h5 className="mb-3">حدد متجرًا لعرض منشوراته</h5>
        <StoreSection
          category_section={category_section}
          setcategory_section={setcategory_section}
        />
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : info.length > 0 ? (
          <TableContainer component={Paper} className="mt-4">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">رقم</TableCell>
                  <TableCell align="center">متجر</TableCell>
                  <TableCell align="center">محتوى</TableCell>
                  <TableCell align="center">الصور</TableCell>
                  <TableCell align="center"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {info.map((item, index) => (
                  <TableRow
                    key={item.id}
                    id={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index}</TableCell>
                    <TableCell align="center">
                      {langsContext.langState === "en"
                        ? item.store.translations.name.en
                        : ""}
                      {langsContext.langState === "ar"
                        ? item.store.translations.name.ar
                        : ""}
                      {langsContext.langState === "ku"
                        ? item.store.translations.name.ku
                        : ""}
                    </TableCell>
                    <TableCell align="center">{item.text}</TableCell>
                    <TableCell align="center">
                      <Modal i={item} />
                    </TableCell>
                    <TableCell align="center" className="d-grid">
                      <UpdatePost
                        id={item.id}
                        i={item}
                        setReload={setReload}
                        reload={reload}
                      />
                      <DeletePost
                        id={item.id}
                        setReload={setReload}
                        reload={reload}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" className="text-center mt-4" sx={{ mb: 5 }}>
            {" "}
            لا يوجد منشورات{" "}
          </Typography>
        )}
      </Container>
    </>
  );
};

export default PostPage;
