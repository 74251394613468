import React, { useEffect } from 'react'
import PostSection from '../PostSection'
import { useState } from 'react'
import AddImagesPost from './AddImagesPost'
import { Helmet } from 'react-helmet-async'
import { Container, Grid, Skeleton, Stack, Typography } from '@mui/material'
import axios from 'axios'
import { IMAGES_POSTS, STORAGE } from '../../constants/url'
import DeletePostImages from './DeletePostImages'

const PostImages = () => {
    const [category_section, setcategory_section] = useState("");
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState([]);
    const FetchPostImages = async () => {
        setLoading(true);
        await axios
            .get(`${IMAGES_POSTS}/${category_section}`, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                }
            })
            .catch((error) => {

            });

        setLoading(false);
    };
    useEffect(() => {
        FetchPostImages();
    }, [reload, category_section]);
    const skeleton = (
        <>
            <Grid item xs={12} sm={6} md={4} sx={{ padding: '0 5px' }}>
                <Skeleton variant="rounded" animation="wave" width="100%" height={250} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ padding: '0 5px' }}>
                <Skeleton variant="rounded" animation="wave" width="100%" height={250} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ padding: '0 5px' }}>
                <Skeleton variant="rounded" animation="wave" width="100%" height={250} />
            </Grid>
        </>
    );
    return (
        <>
            <Helmet>
                <title>  منشورات | Business Iraq </title>
            </Helmet>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        صور المنشورات
                    </Typography>
                    <AddImagesPost setReload={setReload} reload={reload} />
                </Stack>
                <h5 className="mb-3">
                حدد منشورًا لعرض صوره
                </h5>
                <PostSection category_section={category_section} setcategory_section={setcategory_section} />
                <Grid container spacing={3}>
                    {loading ? (
                        skeleton
                    ) : (
                        <>
                            {category_section && info.length > 0 ? (
                                info.map((item) => (
                                    <Grid item xs={12} sm={6} md={4} key={item.id} id={item.id}>
                                        <div className="box-cat position-relative rounded shadow text-center mt-4 py-4">
                                            <div className="pt-5">
                                                <img src={`${STORAGE}${item.image}`} />
                                            </div>

                                            <div className="position-absolute top-0 end-0 py-2 px-3">
                                                {/* <UpdateCardCat id={item.id} i={item} setReload={setReload} reload={reload} /> */}
                                                <DeletePostImages id={item.id} setReload={setReload} reload={reload} />

                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            ) : (
                                ""
                            )}
                        </>
                    )}
                </Grid>
            </Container>
        </>
    )
}

export default PostImages