import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Helmet } from "react-helmet-async";
import { STORAGE, STORES } from "../../constants/url";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "./Modal";
import AddStore from "./AddStore";
import ProductBySrore from "./ProductBySrore";
import LangContext from "../../context/LangContext";
import PlanDetailsModal from "./PlanDetailsModal";
import UpdateStore from "./UpdateStore"; // Import UpdateStore component
import DeleteStore from "./DeleteStore"; // Import DeleteStore component

const StorePage = () => {
  const token = localStorage.getItem("token");
  const langsContext = useContext(LangContext);
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [planModalOpen, setPlanModalOpen] = useState(false);

  const FetchStore = async () => {
    setLoading(true);
    await axios
      .get(STORES, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setInfo(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 500) {
          navigate("/login");
          localStorage.removeItem("token");
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    FetchStore();
  }, [reload]);

  const handleOpenPlanModal = (plan) => {
    setSelectedPlan(plan);
    setPlanModalOpen(true);
  };

  const handleClosePlanModal = () => {
    setPlanModalOpen(false);
    setSelectedPlan(null);
  };

  return (
    <>
      <Helmet>
        <title> متاجر | بزنس العراق </title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Stores
          </Typography>
          <AddStore setReload={setReload} reload={reload} />
        </Stack>
        {loading ? (
          <Skeleton animation="wave" height={500} variant="rectangular" />
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">الاسم</TableCell>
                    <TableCell align="center">اللوغو</TableCell>
                    <TableCell align="center">الصورة</TableCell>
                    <TableCell align="center">الفئة</TableCell>
                    <TableCell align="center">رقم الهاتف</TableCell>
                    <TableCell align="center">العنوان</TableCell>
                    <TableCell align="center">المنتجات</TableCell>
                    <TableCell align="center">معلومات إضافية</TableCell>
                    <TableCell align="center">Details</TableCell>
                    <TableCell align="center">Actions</TableCell> {/* Added Actions column */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {info.length ? (
                    info.map((item) => {
                      const hasInvalidSubscription = item.plans && item.plans.some(plan => plan.pivot.is_valid !== 1);
                      const hasSubscription = item.plans && item.plans.length > 0;

                      return (
                        <TableRow
                          key={item.id}
                          id={item.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            {langsContext.langState === "en"
                              ? item.translations.name.en
                              : ""}
                            {langsContext.langState === "ar"
                              ? item.translations.name.ar
                              : ""}
                            {langsContext.langState === "ku"
                              ? item.translations.name.ku
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            <img src={`${STORAGE}${item.Logo}`} alt="Logo" />
                          </TableCell>
                          <TableCell align="center">
                            <img src={`${STORAGE}${item.image}`} alt="Image" />
                          </TableCell>
                          <TableCell align="center">
                            {langsContext.langState === "en"
                              ? item.category.translations.name.en
                              : ""}
                            {langsContext.langState === "ar"
                              ? item.category.translations.name.ar
                              : ""}
                            {langsContext.langState === "ku"
                              ? item.category.translations.name.ku
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {item.phone_number}
                          </TableCell>
                          <TableCell align="center">
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${item.location}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <OpenInNewIcon />
                            </a>
                          </TableCell>
                          <TableCell align="center">
                            <ProductBySrore i={item} id={item.id} />
                          </TableCell>
                          <TableCell align="center">
                            <Modal id={item.id} i={item} />
                          </TableCell>
                          <TableCell align="center">
                            {hasSubscription && hasInvalidSubscription && (
                              <IconButton onClick={() => handleOpenPlanModal(item.plans[0])}>
                                <VisibilityIcon />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell align="center"> {/* Added Actions column */}
                            <UpdateStore reload={reload} setReload={setReload} id={item.id} i={item} />
                            <DeleteStore reload={reload} setReload={setReload} id={item.id} />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <Typography
                      variant="h6"
                      className="text-center"
                      sx={{ mb: 5 }}
                    >
                      لا متاجر
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Container>
      <PlanDetailsModal
        open={planModalOpen}
        handleClose={handleClosePlanModal}
        planDetails={selectedPlan}
        token={token}
        setReload={setReload}
        reload={reload}
      />
    </>
  );
};

export default StorePage;
