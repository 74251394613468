import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Alert, AlertTitle, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PLANS } from "../../constants/url";

const AddPlan = ({ reload, setReload }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [price, setPrice] = useState("");
  const [period, setPeriod] = useState("");
  const [products_number, setProducts_number] = useState("");
  const [posts_number, setPosts_number] = useState("");
  const [videos_number, setVideos_number] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [error, setError] = useState(false);

  const periodOptions = [
    { label: "شهري", value: "monthly" },
    { label: "سنوي", value: "annual" },
  ];

  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage(false);
  };

  const AddCategorySubmit = () => {
    setLoading(true);
    const data = {
      price: price,
      period: period,
      products_number: products_number,
      posts_number: posts_number,
      videos_number: videos_number,
    };

    axios
      .post(PLANS, data, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setVisible(false);
        setError(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("token");
        }
        setError(true);
        showErrorAlert(error.response.data.errors);
        console.log(error.response.data.errors);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const footerContent = (
    <div>
      <Button
        label="إغلاق"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      {loading ? (
        <Button className=" p-button-text custom-btn2">
          <CircularProgress style={{ color: "#fff" }} size={30} />
        </Button>
      ) : (
        <Button
          label="حفظ"
          icon="pi pi-check"
          onClick={AddCategorySubmit}
          className="p-button-text custom-btn2"
        />
      )}
    </div>
  );

  return (
    <div className="card flex justify-content-center">
      <Button
        label="خطة اشتراك جديدة"
        icon="pi pi-plus"
        onClick={() => setVisible(true)}
        className="custom-btn"
      />
      <Dialog
        header="تفاصيل خطة الاشتراك"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        {error ? (
          <Alert severity="error" className="mb-4">
            <AlertTitle>خطأ</AlertTitle>
            <p>{erroralert.price}</p>
            <p>{erroralert.period}</p>
            <p>{erroralert.products_number}</p>
            <p>{erroralert.posts_number}</p>
            <p>{erroralert.videos_number}</p>
          </Alert>
        ) : (
          ""
        )}
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="price">سعر</label>
          <InputText
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="period">فترة</label>
          <Dropdown
            value={period}
            options={periodOptions}
            onChange={(e) => setPeriod(e.value)}
            placeholder="اختر فترة"
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="products_number">عدد المنتجات</label>
          <InputText
            value={products_number}
            type="number"
            onChange={(e) => setProducts_number(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="posts_number">عدد المنشورات</label>
          <InputText
            value={posts_number}
            type="number"
            onChange={(e) => setPosts_number(e.target.value)}
            className="w-full mb-2"
          />
        </div>
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="videos_number">عدد الفيديوهات</label>
          <InputText
            value={videos_number}
            type="number"
            onChange={(e) => setVideos_number(e.target.value)}
            className="w-full mb-2"
          />
        </div>
      </Dialog>
      <Snackbar
        open={message}
        autoHideDuration={3000}
        onClose={handleCloseMessage}
        className="ic"
      >
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: "100%" }}
          style={{ background: "rgb(46, 125, 50)", color: "#fff" }}
        >
          النجاح! المعلومات المحدثة بنجاح
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddPlan;
