import { Helmet } from "react-helmet-async";
// @mui
import { Typography } from "@mui/material";
// components
import Logo from "../components/logo/Logo";
// sections
import { LoginForm } from "../sections/auth/login";

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title> تسجيل الدخول | بزنس العراق </title>
      </Helmet>
      <div className="bg-login">
        <div className="container pt-5">
          <div className="row align-items-center text-center justify-content-center pt-5">
            <div className="col-md-8 col-sm-12 col-12 ">
              <div className="box">
                <Typography variant="h3" className="text-center mb-4">
                  مرحبًا بكم في Business Iraq
                </Typography>
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
