import React, { useState, useEffect } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import ImageUploading from 'react-images-uploading';
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { InputTextarea } from "primereact/inputtextarea";
import Checkbox from '@mui/material/Checkbox';
import { PRODUCTS, STORAGE } from "../../constants/url";
import { useNavigate } from "react-router-dom";
const UpdateProduct = ({ reload, setReload, id, i }) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [details, setDetils] = useState("");
    const [category_section, setcategory_section] = useState("");
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameEn, setNameEn] = useState(i.translations.name.en);
    const [nameAr, setNameAr] = useState(i.translations.name.ar);
    const [nameKu, setNameKu] = useState(i.translations.name.ku);
    const [descEn, setDescEn] = useState(i.translations.description.en);
    const [descAr, setDescAr] = useState(i.translations.description.ar);
    const [descKu, setDescKu] = useState(i.translations.description.ku);
    const [price, setPrice] = useState(i.price);
    const [SKU, setSKU] = useState(i.SKU);
    const [checked1, setChecked1] = useState(i.warrantyReturningPolicy === false ? 0 : 1);
    const [checked2, setChecked2] = useState(i.freeZone === false ? 0 : 1);
    const [images, setImages] = useState([]);
    const [showImage, setshowImage] = useState(i.main_photo);
    const [images2, setImages2] = useState([]);
    const [size, setSize] = useState("");
    const [colors, setColors] = useState([]);
    const [message, setMessage] = useState(false);
    let prevElements = []
    const mappedArray = details.variations && details.variations.map((item) => {
        const currentElement = item;
        const previousElementsCopy = [...prevElements];
        return previousElementsCopy.push(currentElement);

    });

    const [elements, setElements] = useState([]);

    const old1 = details.variations && details.variations.map((item) => (item.size.size))
    const old2 = details.variations && details.variations.map((item) => (item.colors.length && item.colors.map((color) => (
        `${color}, `
    ))))

    const elements1 = [old1, old2]
    const [newArray, setNewArray] = useState([]);
    function handleButtonClick() {
        setElements((prevElements) => [...prevElements, [size, colors]]);
        // const mergedArray = [[size, colors], elements1];
        // setNewArray(mergedArray);
        // console.log(mergedArray)
        setSize('')
        setColors([])
    }
  


    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };

    const onChange2 = (imageList, addUpdateIndex) => {
        setImages2(imageList);
    };
    const handleChange1 = (event) => {
        setChecked1(event.target.checked ? 1 : 0);

    };
    const handleChange2 = (event) => {
        setChecked2(event.target.checked ? 1 : 0);
    };
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };


    const FetchProductsDetails = async () => {
        setLoading(true);
        await axios
            .get(`${PRODUCTS}/${i.id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setDetils(response.data.data);

                }
            })
            .catch((error) => {
                if (error.response.status === 401 || error.response.status === 500) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            });

        setLoading(false);
    };
    const AddCategorySubmit = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name[en]", nameEn);
        formData.append("name[ar]", nameAr);
        formData.append("name[ku]", nameKu);
        formData.append("description[en]", descEn);
        formData.append("description[ar]", descAr);
        formData.append("description[ku]", descKu);
        formData.append("price", price);
        formData.append("warrantyReturningPolicy", checked1);
        formData.append("freeZone", checked2);
        formData.append("SKU", SKU);
        // formData.append("store_id", category_section);
        if (images.length != 0) {
            formData.append('main_photo', images[0].file);
        }

        images2.map((item, index) => (
            formData.append(`images[${index}]`, item.file)
        ))
        elements.map((i, index) => (
            formData.append(`variations[${index}][size]`, i[index]),
            i[1].map((item, index2) => (
                formData.append(`variations[${index}][colors][${index2}]`, item)

            ))

        ))
        axios
            .post(`${PRODUCTS}/${id}?_method=PUT`, formData, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {

                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);


            })

            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    useEffect(() => {
        FetchProductsDetails();

    }, []);
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={AddCategorySubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );



    return (
        <>

            <Button icon="pi pi-pencil " onClick={() => setVisible(true)}
                className="p-button-success border-0 p-button-icon-only" />
            <Dialog header="Product Details" visible={visible} style={{ width: '50vw' }}
                onHide={() => setVisible(false)} footer={footerContent}>


                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الاسم بالأنكليزية</label>
                    <InputText value={nameEn} onChange={(e) => setNameEn(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الاسم بالعربية</label>
                    <InputText value={nameAr} onChange={(e) => setNameAr(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الاسم بالكردية </label>
                    <InputText value={nameKu} onChange={(e) => setNameKu(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الوصف باللغة الإنجليزية</label>
                    <InputTextarea autoResize value={descEn} onChange={(e) => setDescEn(e.target.value)}
                        className="w-full mb-2" rows={5} cols={30} />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الوصف عربي</label>
                    <InputTextarea autoResize value={descAr} onChange={(e) => setDescAr(e.target.value)}
                        className="w-full mb-2" rows={5} cols={30} />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الوصف كردي </label>
                    <InputTextarea autoResize value={descKu} onChange={(e) => setDescKu(e.target.value)}
                        className="w-full mb-2" rows={5} cols={30} />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">السعر</label>
                    <InputText value={price} onChange={(e) => setPrice(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">SKU</label>
                    <InputText value={SKU} onChange={(e) => setSKU(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex gap-2 my-3">
                    <label htmlFor="username">سياسة إرجاع الضمان</label>
                    <Checkbox
                        className="p-0"
                        checked={checked1}
                        onChange={handleChange1}

                    />
                </div>
                <div className="d-flex gap-2 my-3">
                    <label htmlFor="username">منطقة حرة</label>
                    <Checkbox
                        className="p-0"
                        checked={checked2}
                        onChange={handleChange2}

                    />
                </div>
                {/* <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">Select Store</label>
                    <StoreSection category_section={category_section} setcategory_section={setcategory_section} />
                </div> */}

                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">مقاس</label>
                    <InputText value={size} onChange={(e) => setSize(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">أدخل الألوان مفصولة بفاصلة</label>
                    <InputText value={colors.join(',')} onChange={(e) => setColors(e.target.value.split(','))}
                        className="w-full mb-2" />
                </div>

                <div style={{ width: size, height: size }}>
                    {colors.map((color, index) => (
                        <div key={index} style={{ backgroundColor: color, width: '50%', height: '50%', display: 'inline-block' }} />
                    ))}
                </div>
                <button onClick={handleButtonClick} className="custom-btn2 border-0 w-100 py-2 mt-2 mb-3">
                إضافة الحجم والألوان
                </button>

                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">تحميل الصورة الرئيسية</label>
                    <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
                        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                            <div className="upload__image-wrapper position-relative mb-3">
                                <Button label="Choose" icon="pi pi-plus"
                                    className="btn-add w-100 d-block  py-2"
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    type="button" />
                                {imageList.length > 0 ? (
                                    imageList.map((image, index) => (
                                        <div key={index} className="image-item d-flex align-items-center">
                                            <img src={image['data_url']} alt="" width="100" />

                                            <div className="image-item__btn-wrapper">
                                                <Button icon="pi pi-trash" onClick={() => onImageRemove(index)}
                                                    className="p-button-text bg-danger text-white ms-3" />

                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <img src={`${STORAGE}${showImage}`} width="100%" />
                                )}
                            </div>
                        )}
                    </ImageUploading>
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">تحميل صور المنتج</label>
                    <ImageUploading multiple value={images2} onChange={onChange2} dataURLKey="data_url">
                        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                            <div className="upload__image-wrapper position-relative mb-3">
                                <Button label="Choose" icon="pi pi-plus"
                                    className="btn-add w-100 d-block  py-2"
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    type="button" />
                                {imageList.length > 0 ? (
                                    imageList.map((image, index) => (
                                        <div key={index} className="image-item d-flex align-items-center">
                                            <img src={image['data_url']} alt="" width="100" />

                                            <div className="image-item__btn-wrapper">
                                                <Button icon="pi pi-trash" onClick={() => onImageRemove(index)}
                                                    className="p-button-text bg-danger text-white ms-3" />

                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    details.images && details.images.map((item) => (
                                        <div key={item.id} id={item.id}><img src={`${STORAGE}${item.url}`} /></div>
                                    ))
                                )}
                            </div>
                        )}
                    </ImageUploading>
                </div>
            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
                    نجاح! تم تحديث المعلومات بنجاح
                </Alert>
            </Snackbar>
        </>
    )
}

export default UpdateProduct