import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import Iconify from '../../../components/iconify';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from 'yup'
import axios from 'axios';
import { LOGIN } from '../../../constants/url';

export default function LoginForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(false)
  const [erroralert, showErrorAlert] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSubmitLogin = async () => {
    setLoading(true);
    const data = {
      phone_number: formik.values.phone_number,
      password: formik.values.password,
    }
    await axios.post(LOGIN, data,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },

      })

      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("token", response.data.data.authorization.token)
          localStorage.setItem("userName", response.data.data.user.name)
          localStorage.setItem("phone", response.data.data.user.phone_number)
          response.data.data.user.role_name === "admin"
            ? setTimeout(() => {
              navigate('/dashboard/app', { replace: true })
            }, 3000)
            :
            navigate('/unauthorized')
          setOpen(true);
          setMessage(false)

        }

      })
      .catch((error) => {
        setLoading(false)
        setMessage(true)
        console.log(error)
        showErrorAlert(error.response.data.message)
      });

    setLoading(false)

  }


  const validationSchema = Yup.object().shape({
    phone_number: Yup.number()
      .required("Enter your phone number"),
    password: Yup.string().required('Enter your password')

  });

  const formik = useFormik({
    initialValues: {
      phone_number: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleSubmitLogin();
    },
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (

    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {
          message ? <Alert severity="error" className='mb-4'>
            <AlertTitle>Error</AlertTitle>
            <div> <strong>{erroralert} </strong>

            </div>
          </Alert> : ''}
        <Stack spacing={3}>
          <TextField name="phone_number" label="Phone Number" variant="filled"
            {...getFieldProps("phone_number")} error={Boolean(touched.phone_number && errors.phone_number)}
            helperText={touched.phone_number && errors.phone_number} />

          <TextField
            name="password"
            label="Password"
            variant="filled"
            className='mb-4 pass'
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            {...getFieldProps("password")}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {loading ?
          <LoadingButton fullWidth size="large" type="submit" variant="contained" className='btn-bg' ><CircularProgress style={{ color: '#fff' }} size={30} /></LoadingButton> :
          <LoadingButton fullWidth size="large" type="submit" variant="contained" className='btn-bg' >
            Login
          </LoadingButton>}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} className="ic">
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}>
            success! Logged in successfully
          </Alert>
        </Snackbar>
      </Form>
    </FormikProvider>

  );
}
