import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { PLANS } from "../../constants/url";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";


const DeletePlan = ({ reload, setReload, id }) => {
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const token = localStorage.getItem('token');
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };
    const DeleteContactSubmit = async () => {
        setLoading(true);
        await axios
            .delete(`${PLANS}/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {

                setTimeout(() => {
                    setReload(!reload)
                }, 1500);
                setVisible(false);
                setMessage(true)


            })
            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }

            });
        setLoading(false)
    }
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className="  text-white p-button-danger">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حذف" icon="pi pi-check" onClick={DeleteContactSubmit} className=" text-white p-button-danger" />
            )}

        </div>
    );
    return (
        <>
            <Button icon="pi pi-trash" onClick={() => setVisible(true)}
                className="text-white p-button-danger p-button-icon-only border-0" />
            <Dialog header="Delete Confirmation" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <h6 >هل تريد حذف هذا الاشتراك ؟</h6>
            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
                    النجاح! تم حذف المعلومات بنجاح
                </Alert>
            </Snackbar>
        </>
    )
}

export default DeletePlan