import { Container, Skeleton, Typography } from "@mui/material"
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async"
import { CONTACT } from "../../constants/url";
import UpdateContact from "./UpdateContact";


const ContactEmail = () => {
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const FetchContact = async () => {
        setLoading(true);
        await axios
            .get(CONTACT, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                }
            })
            .catch((error) => {

            });

        setLoading(false);
    };
    useEffect(() => {
        FetchContact();
    }, [reload]);
    return (
        <>
            <Helmet>
                <title>  البريد الإلكتروني | Business Iraq </title>
            </Helmet>
            <Container>
                <Typography variant="h4" className="mb-4">
                البريد الإلكتروني
                </Typography>
                <div className="card card-content ">
                    {loading ? <Skeleton animation="wave" height={500} variant="rectangular" /> :
                        <>
                            <h5 className="px-4 pt-4 pb-3">{info.contact_email}</h5>
                            <UpdateContact i={info.contact_email} setReload={setReload} reload={reload} />
                        </>
                    }
                </div>

            </Container>
        </>
    )
}

export default ContactEmail