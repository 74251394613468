import React from "react";
import { Dialog } from "primereact/dialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button } from "primereact/button";
import { useState } from "react";
import LangContext from "../../context/LangContext";
import { useContext } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
const Modal = ({ i }) => {
  const [visible, setVisible] = useState(false);
  const langsContext = useContext(LangContext);
  return (
    <>
      <Button
        severity="secondary"
        icon="pi pi-eye"
        onClick={() => setVisible(true)}
        id={i.id}
      />
      <Dialog
        header="معلومات المتجر"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <p>
          <span className="fw-bold">الوصف:</span>
          {langsContext.langState === "en" ? i.translations.Description.en : ""}
          {langsContext.langState === "ar" ? i.translations.Description.ar : ""}
          {langsContext.langState === "ku" ? i.translations.Description.ku : ""}
        </p>
        <p>
          <span className="fw-bold">العنوان:</span>
          {langsContext.langState === "en" ? i.translations.address.en : ""}
          {langsContext.langState === "ar" ? i.translations.address.ar : ""}
          {langsContext.langState === "ku" ? i.translations.address.ku : ""}
        </p>
        <p>
          <span className="fw-bold"> رقم الـ whatsapp:</span>{" "}
          {i.whatsapp_number}
        </p>
        <p>
          <span className="fw-bold"> رابط الـ facebook:</span>{" "}
          {i.facebook_page_link}
        </p>
        <p>
          <span className="fw-bold"> رابط الـ tiktok:</span>{" "}
          {i.tiktok_page_link}
        </p>
        <p className="d-flex">
          <span className="fw-bold pe-2">التوصيل:</span>
          {i.possibility_of_delivery === true ? (
            <div>
              {" "}
              <CheckCircleIcon color="success" />
            </div>
          ) : (
            <HighlightOffIcon color="error" />
          )}
        </p>
        {i.possibility_of_delivery === true ? (
          <p>
            <span className="fw-bold">تكلفة التوصيل:</span> {i.delivery_cost}
          </p>
        ) : (
          ""
        )}
        <p>
          <span className="fw-bold pe-2">ساعات العمل: </span>{" "}
          {i.always_open === true ? (
            <p>مفتوح دوماً</p>
          ) : (
            <TableContainer component={Paper} className="mt-3">
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">الايام</TableCell>
                    <TableCell align="center">وقت الفتح</TableCell>
                    <TableCell align="center">وقت الإغلاق</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {i.open_close_times.map((item) => (
                    <TableRow
                      key={item.id}
                      id={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="center">{item.day}</TableCell>
                      <TableCell align="center">{item.open_time}</TableCell>
                      <TableCell align="center">{item.close_time}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </p>
      </Dialog>
    </>
  );
};

export default Modal;
