import React, { useContext } from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PRODUCTS, PRODUCTS_STORES, STORAGE } from '../../constants/url';
import { useEffect } from 'react';
import {
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import LangContext from '../../context/LangContext';
const ProductBySrore = (i) => {
    const langsContext = useContext(LangContext);
    const token = localStorage.getItem('token')
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const FetchProducts = async () => {
        setLoading(true);
        await axios
            .get(`${PRODUCTS_STORES}${i.id}`, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                }
            })
            .catch((error) => {
                // if (error.response.status === 401 ) {
                //     navigate('/login');
                //     localStorage.removeItem('token');
                // }
            });

        setLoading(false);
    };
    useEffect(() => {
        FetchProducts();
    }, []);
    console.log(info)
    return (
        <>
            <Button severity="secondary" icon="pi pi-eye" onClick={() => setVisible(true)} id={i.id} />
            <Dialog header="Product Information" visible={visible}
                style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                {loading ? <Skeleton animation="wave" height={500} variant="rectangular" /> :
                    <>
                        {info.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'>اسم</TableCell>
                                            <TableCell align="center">الصورة الرئيسية</TableCell>
                                            <TableCell align="center">وصف</TableCell>
                                            <TableCell align="center">سعر</TableCell>
                                            <TableCell align="center">نسبة الخصم</TableCell>
                                            <TableCell align="center">سعر العرض</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {info.map((item) => (
                                            <TableRow
                                                key={item.id} id={item.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="center">
                                                    {langsContext.langState === 'en' ? item.translations.name.en : ''}
                                                    {langsContext.langState === 'ar' ? item.translations.name.ar : ''}
                                                    {langsContext.langState === 'ku' ? item.translations.name.ku : ''}
                                                </TableCell>
                                                <TableCell align="center"> <img src={`${STORAGE}${item.main_photo}`} /></TableCell>
                                                <TableCell align="center">
                                                    {langsContext.langState === 'en' ? item.translations.description.en : ''}
                                                    {langsContext.langState === 'ar' ? item.translations.description.ar : ''}
                                                    {langsContext.langState === 'ku' ? item.translations.description.ku : ''}
                                                </TableCell>
                                                <TableCell align="center">{item.price}</TableCell>
                                                <TableCell align="center">{item.discountPercentage != null ? item.discountPercentage :
                                                    <CancelIcon color="error" />}</TableCell>
                                                <TableCell align="center">{item.offerPrice != null ? item.offerPrice :
                                                    <CancelIcon color="error" />}</TableCell>
                                            </TableRow>
                                        ))}


                                    </TableBody>
                                </Table>
                            </TableContainer>
                            : <Typography variant="h6" className='text-center' sx={{ mb: 5 }}> لا يوجد منتج في هذا المتجر  </Typography>}
                    </>
                }
            </Dialog>



        </>
    )
}

export default ProductBySrore