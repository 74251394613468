
import { Link as RouterLink, Navigate, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
import { Helmet } from "react-helmet-async";



export default function Unauthorized() {
    const navigate = useNavigate();
    const handeClickBack = () => {
        navigate(-1);
    };
    return (
        <>
            <Helmet>
                <title> Unauthorized | Business Iraq </title>
            </Helmet>
            <Container className="text-center pt-5">
                <Typography variant="h3" paragraph>
                    Sorry, you are Unauthorized to access this page !
                </Typography>
                <img src={require('../assets/img/401.png')} width="300px" className="m-auto" />

            </Container>
        </>
    );
}
