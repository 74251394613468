import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { Alert, AlertTitle, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CREATE_USERS } from "../../constants/url";


const AddUser = ({ reload, setReload }) => {
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [phone, setPhone] = useState("");
    const [role, setRole] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState(false);
    const [erroralert, showErrorAlert] = useState([]);
    const [error, setError] = useState(false);
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };
    const AddUserSubmit = () => {
        setLoading(true);
        const data = {
            name: name,
            phone_number: phone,
            role_name: role,
            password: password
        }
        axios
            .post(CREATE_USERS, data, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);
                setError(false);
            })

            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
                setError(true);
                showErrorAlert(error.response.data.errors);
                console.log(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={AddUserSubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );
    return (
        <div className="card flex justify-content-center">
            <Button label="New User" icon="pi pi-plus" onClick={() => setVisible(true)} className="custom-btn" />
            <Dialog header="User Details" visible={visible} style={{ width: '50vw' }}
                onHide={() => setVisible(false)} footer={footerContent}>
                {error ? (
                    <Alert severity="error" className="mb-4">
                        <AlertTitle>Error</AlertTitle>
                        <p>{erroralert.name}</p>
                        <p>{erroralert.password}</p>
                        <p>{erroralert.phone_number}</p>
                        <p>{erroralert.role_name}</p>
                    </Alert>
                ) : (
                    ''
                )}
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">اسم</label>
                    <InputText value={name} onChange={(e) => setName(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">رقم الهاتف</label>
                    <InputText value={phone} onChange={(e) => setPhone(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">اسم الدور </label>
                    <InputText value={role} onChange={(e) => setRole(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">كلمة السر </label>
                    <InputText value={password} onChange={(e) => setPassword(e.target.value)}
                        className="w-full mb-2" />
                </div>
            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
                    نجاح! تمت إضافة المستخدم بنجاح
                </Alert>
            </Snackbar>
        </div>
    )
}

export default AddUser