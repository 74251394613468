import { Container, Skeleton, Typography } from "@mui/material"
import axios from "axios";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async"
import { POLICY } from "../../constants/url";
import UpdatePolicy from "./UpdatePolicy";


const PolicyPage = () => {
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const FetchPolicy = async () => {
        setLoading(true);
        await axios
            .get(POLICY, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                }
            })
            .catch((error) => {

            });

        setLoading(false);
    };
    useEffect(() => {
        FetchPolicy();
    }, [reload]);
    return (
        <>
            <Helmet>
                <title>  سياسة الخصوصية | Business Iraq </title>
            </Helmet>
            <Container>
                <Typography variant="h4" className="mb-4">
                سياسة الخصوصية
                </Typography>
                <div className="card card-content ">
                    {loading ? <Skeleton animation="wave" height={500} variant="rectangular" /> :
                        <>
                            <div dangerouslySetInnerHTML={{ __html: ` <h5 class="content">${info.policy}</h5>` }} />
                            <UpdatePolicy i={info} setReload={setReload} reload={reload} />
                        </>
                    }
                </div>

            </Container>
        </>
    )
}

export default PolicyPage