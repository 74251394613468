import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SELLER_STORE, STORAGE } from '../../constants/url';
import { Dialog } from 'primereact/dialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button } from 'primereact/button';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Container,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const StoreBySeller = ({ id, i }) => {
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const langsContext = useContext(LangsContext);

  const FetchStore = async () => {
    setLoading(true);
    await axios
      .get(`${SELLER_STORE}${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setList(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 401 || error.response.status === 500) {
          navigate("/login");
          localStorage.removeItem("token");
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    FetchStore();
  }, [id]);

  return (
    <>
      <Button
        severity="secondary"
        icon="pi pi-eye"
        onClick={() => setVisible(true)}
        id={i.id}
      />
      <Dialog
        header="Store Details"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">اسم</TableCell>
                <TableCell align="center">شعار</TableCell>
                <TableCell align="center">صورة</TableCell>
                <TableCell align="center">فئة</TableCell>
                <TableCell align="center">رقم التليفون</TableCell>
                <TableCell align="center">موقع</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 ? (
                list.map((item) => (
                  <TableRow
                    key={item.id}
                    id={item.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">
                      {langsContext.langState === "en"
                        ? item.translations.name.en
                        : ""}
                      {langsContext.langState === "ar"
                        ? item.translations.name.ar
                        : ""}
                      {langsContext.langState === "ku"
                        ? item.translations.name.ku
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      <img src={`${STORAGE}${item.Logo}`} />
                    </TableCell>
                    <TableCell align="center">
                      <img src={`${STORAGE}${item.image}`} />
                    </TableCell>
                    <TableCell align="center">
                      {langsContext.langState === "en"
                        ? item.category.translations.name.en
                        : ""}
                      {langsContext.langState === "ar"
                        ? item.category.translations.name.ar
                        : ""}
                      {langsContext.langState === "ku"
                        ? item.category.translations.name.ku
                        : ""}
                    </TableCell>
                    <TableCell align="center">{item.phone_number}</TableCell>
                    <TableCell align="center">
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${item.location}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <OpenInNewIcon />
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Typography variant="h6" className="text-center" sx={{ mb: 5 }}>
                  لا متاجر
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Dialog>
    </>
  );
};

export default StoreBySeller;
