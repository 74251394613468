import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { STORAGE } from '../../constants/url';
import { useState } from 'react';
import { Typography } from '@mui/material';
import AddImagesPost from './AddImagesPost';

const Modal = ({ i }) => {
    const [visible, setVisible] = useState(false);
    return (
        <>
            <Button severity="secondary" icon="pi pi-eye" onClick={() => setVisible(true)} id={i.id} />
            <Dialog header="Post Images" visible={visible}
                style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <div className='d-flex'>
                    {i.images.length > 0 ?
                        i.images.map((image, index) => (
                            <img src={`${STORAGE}${image.image}`} key={index} />

                        ))
                  
                : <Typography variant="h6" className='text-center mt-4' sx={{ mb: 5 }}> لا توجد صور </Typography>}
                  </div>
            </Dialog>
        </>
    )
}

export default Modal