import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// routes
// import Router from './routes';
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import ScrollToTop from "./components/scroll-to-top";
import "./assets/css/style.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LoginPage from "./pages/LoginPage";
import PrivateRoutes from "./PrivateRoutes";
import CategoryPage from "./pages/category/CategoryPage";
import Unauthorized from "./pages/Unauthorized";
import StorePage from "./pages/stores/StorePage";
import UserPage from "./pages/user/UserPage";
import ProductPage from "./pages/product/ProductPage";
import SliderPage from "./pages/slider/SliderPage";
import PostPage from "./pages/post/PostPage";
import PostImages from "./pages/post/PostImages";
import VideoPage from "./pages/video/VideoPage";
import DashboardAppPage from './pages/DashboardAppPage';
import PlansPage from "./pages/plans/PlansPage";
import PolicyPage from "./pages/policy/PolicyPage";
import TermsPage from "./pages/terms/TermsPage";
import ContactEmail from "./pages/contact/ContactEmail";
import PolicyContent from "./publicRoute/PolicyContent";
import TermsContent from "./publicRoute/TermsContent";
import SellersPage from "./pages/sellers/SellersPage";


// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles /> 
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path="app" element={<DashboardAppPage />} />
            <Route path="categories" element={<CategoryPage />} />
            <Route path="stores" element={<StorePage />} />
            <Route path="products" element={<ProductPage />} />
            <Route path="users" element={<UserPage />} />
            <Route path="slider" element={<SliderPage />} />
            <Route path="posts" element={<PostPage />} />
            <Route path="post-images" element={<PostImages />} />
            <Route path="Videos" element={<VideoPage />} />
            <Route path="plans" element={<PlansPage />} />
            <Route path="policy" element={<PolicyPage />} />
            <Route path="terms" element={<TermsPage />} />
            <Route path="email" element={<ContactEmail />} />
            <Route path="sellers" element={<SellersPage />} />
          </Route>
        </Route>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route element={<LoginPage />} path="/login" />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="privacy-policy" element={<PolicyContent />} />
        <Route path="terms-conditions" element={<TermsContent />} />
      </Routes>
    </ThemeConfig>
  );
} 
