import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import ImageUploading from 'react-images-uploading';
import { CAQTEGORIES, STORAGE } from "../../constants/url";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UpdateCategory = ({ reload, setReload, id, i }) => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameEn, setNameEn] = useState(i.translations.name.en);
    const [nameAr, setNameAr] = useState(i.translations.name.ar);
    const [nameKu, setNameKu] = useState(i.translations.name.ku);
    const [images, setImages] = useState([]);
    const [showImage, setshowImage] = useState(i.image);
    const [message, setMessage] = useState(false);
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };
    const UpdateCategorySubmit = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name[en]", nameEn);
        formData.append("name[ar]", nameAr);
        formData.append("name[ku]", nameKu);
        if (images.length != 0) {
            formData.append('image', images[0].file);
        }
        axios
            .post(`${CAQTEGORIES}/${id}?_method=PUT`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {

                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);


            })

            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={UpdateCategorySubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );

    return (
        <>
            <Button icon="pi pi-pencil " onClick={() => setVisible(true)}
                className="p-button-success border-0 p-button-icon-only" />
            <div className=" flex justify-content-center">

                <Dialog header="تفاصيل الفئة" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>

                    <div className="d-flex flex flex-column gap-2">
                        <label htmlFor="username">الاسم الانكليزية</label>
                        <InputText value={nameEn} onChange={(e) => setNameEn(e.target.value)}
                            className="w-full mb-2" />
                    </div>
                    <div className="d-flex flex flex-column gap-2">
                        <label htmlFor="username">الاسم العربي</label>
                        <InputText value={nameAr} onChange={(e) => setNameAr(e.target.value)}
                            className="w-full mb-2" />
                    </div>
                    <div className="d-flex flex flex-column gap-2">
                        <label htmlFor="username">اسم كردي </label>
                        <InputText value={nameKu} onChange={(e) => setNameKu(e.target.value)}
                            className="w-full mb-2" />
                    </div>
                    <div className="d-flex flex flex-column gap-2">
                        <label htmlFor="username">تحميل الصورة</label>
                        <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
                            {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                                <div className="upload__image-wrapper position-relative mb-3">
                                    <Button label="Choose" icon="pi pi-plus"
                                        className="btn-add w-100 d-block  py-2"
                                        style={isDragging ? { color: 'red' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        type="button" />
                                    {imageList.length > 0 ? (
                                        imageList.map((image, index) => (
                                            <div key={index} className="image-item d-flex align-items-center">
                                                <img src={image['data_url']} alt="" width="100" />

                                                <div className="image-item__btn-wrapper">
                                                    <Button icon="pi pi-trash" onClick={() => onImageRemove(index)}
                                                        className="p-button-text bg-danger text-white ms-3" />

                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <img src={`${STORAGE}${showImage}`} width="100%" />
                                    )}
                                </div>
                            )}
                        </ImageUploading>
                    </div>
                    
                </Dialog>
                <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                    <Alert
                        onClose={handleCloseMessage}
                        severity="success"
                        sx={{ width: '100%' }}
                        style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                    >
                        نجاح! المعلومات المحدثة بنجاح
                    </Alert>
                </Snackbar>
            </div>
        </>
    )
}

export default UpdateCategory