import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { VIDEO } from "../../constants/url";
import { Alert, AlertTitle, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import StoreSection from "../StoreSection";
import { InputTextarea } from "primereact/inputtextarea";
import { useRef } from "react";
import { InputText } from "primereact/inputtext";

const AddVideo = ({ reload, setReload }) => {
    const token = localStorage.getItem('token');
    const [category_section, setcategory_section] = useState('');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [erroralert, showErrorAlert] = useState([]);
    const [error, setError] = useState(false);
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };


    const [video, setVideo] = useState();
    const [source, setSource] = useState();
    console.log(video)
    const handleVideoUpload = (e) => {
        setVideo(e.target.files[0]);
        const file = e.target.files[0];
        const url = URL.createObjectURL(file);
        setSource(url);
    };

    const AddVideoSubmit = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('video', video);
        formData.append("store_id", category_section);
        axios
            .post(VIDEO, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);
                setError(false);

            })

            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
                setError(true);
                showErrorAlert(error.response.data.errors);
                console.log(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={AddVideoSubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );
    return (
        <div className="card flex justify-content-center">
            <Button label="New Video" icon="pi pi-plus" onClick={() => setVisible(true)} className="custom-btn" />
            <Dialog header="Video Details" visible={visible} style={{ width: '50vw' }}
                onHide={() => setVisible(false)} footer={footerContent}>
                {error ? (
                    <Alert severity="error" className="mb-4">
                        <AlertTitle>خطأ</AlertTitle>
                        <p>{erroralert.video}</p>
                        <p>{erroralert.store_id}</p>
                    </Alert>
                ) : (
                    ''
                )}
                {!video &&
                    <InputText type="file" accept="video/*" onChange={handleVideoUpload}
                        className="w-full mb-2" />
                }
                {video && (
                    <video
                        className="VideoInput_video"
                        width="100%"
                        controls
                        src={source}
                    />
                )}
                <div className="d-flex flex flex-column  gap-2">
                    <label htmlFor="username">اختر متجر</label>
                    <StoreSection category_section={category_section} setcategory_section={setcategory_section} />

                </div>

            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
نجاح! تمت إضافة المعلومات بنجاح                </Alert>
            </Snackbar>
        </div>
    )
}

export default AddVideo