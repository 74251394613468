
import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import ImageUploading from 'react-images-uploading';
import { CAQTEGORIES, STORAGE, STORES } from "../../constants/url";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import axios from "axios";
import CategorySection from "../CategorySection";
import { Sidebar } from 'primereact/sidebar';
import { useRef } from "react";
import Checkbox from '@mui/material/Checkbox';
import { json, useNavigate } from "react-router-dom";

const UpdateStore = ({ reload, setReload, id, i }) => {
    
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const [closeTime, setCloseTime] = useState("");
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameEn, setNameEn] = useState(i.translations.name.en);
    const [nameAr, setNameAr] = useState(i.translations.name.ar);
    const [nameKu, setNameKu] = useState(i.translations.name.ku);
    const [descEn, setDescEn] = useState(i.translations.Description.en);
    const [descAr, setDescAr] = useState(i.translations.Description.ar);
    const [descKu, setDescKu] = useState(i.translations.Description.ku);
    const [number, setNumber] = useState(i.whatsapp_number);
    const [phone, setPhone] = useState(i.phone_number);
    const [link1, setLink1] = useState(i.facebook_page_link);
    const [link2, setLink2] = useState(i.tiktok_page_link);
    const [location, setLocation] = useState(i.location);
    const [addressEn, setAddressEn] = useState(i.translations.address.en);
    const [addressAr, setAddressAr] = useState(i.translations.address.ar);
    const [addressKu, setAddressKu] = useState(i.translations.address.ku);
    const [category_section, setcategory_section] = useState(i.category_id);
    const [open, setOpen] = useState(i.always_open === true ? "1" : "0");
    const [images, setImages] = useState([]);
    const [showImage, setshowImage] = useState(i.image);
    const [showLogo, setshowLogo] = useState(i.Logo);
    const [logo, setLogo] = useState([]);
    const [checked1, setChecked1] = useState(i.possibility_of_delivery === false ? 0 : 1);
    const [cost, setCost] = useState(i.delivery_cost);
    const [message, setMessage] = useState(false);


    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };
    const onChangeLogo = (imageList, addUpdateIndex) => {
        setLogo(imageList);
    };
    const handleChange1 = (event) => {
        setChecked1(event.target.checked ? 1 : 0);

    };
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };


    const inputRefs = useRef([]);
    let old = i.open_close_times.map((item,index) => item.open_time.slice(0, -3));
    const old2 = i.open_close_times.map((item,index) => item.close_time.slice(0, -3))
    const [g,setG] = useState(old)
    const [g2,setG2] = useState(old2)

   
    const inputRefs2 = useRef([]);
    const handleOpenTime = (index) => {
        setG("");
        const inputValue = inputRefs.current[index].value;

       
        console.log(`Input ${index} changed:`, newStr);


    };
    const handleCloseTime = (index) => {
        setG2("")
        const inputValue = inputRefs2.current[index].value;
        console.log(`Input ${index} changed:`, inputValue);

    };

    const AddCategorySubmit = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("name[en]", nameEn);
        formData.append("name[ar]", nameAr);
        formData.append("name[ku]", nameKu);
        formData.append("Description[en]", descEn);
        formData.append("Description[ar]", descAr);
        formData.append("Description[ku]", descKu);
        formData.append("category_id", category_section);
        formData.append("whatsapp_number", number);
        formData.append("facebook_page_link", link1);
        formData.append("tiktok_page_link", link2);
        formData.append("location", location);
        formData.append("address[en]", addressEn);
        formData.append("address[ar]", addressAr);
        formData.append("address[ku]", addressKu);
        formData.append("phone_number", phone);
        formData.append("possibility_of_delivery", checked1);
        if (checked1 === 1) {
            formData.append("delivery_cost", cost);
        }
        if (open === '1') {
            formData.append("always_open", open);
        } else {
            formData.append("always_open", open);
            days.map((item, index) => {
                formData.append(`openCloseTimes[${index}][day]`, days[index]);
                formData.append(`openCloseTimes[${index}][open_time]`, inputRefs.current[index].value)
                formData.append(`openCloseTimes[${index}][close_time]`, inputRefs2.current[index].value)
            });
        }

        if (images.length != 0) {
            formData.append('image', images[0].file);
        }
        if (images.length != 0) {
            formData.append('logo', logo[0].file);
        }
        axios
            .post(`${STORES}/${id}?_method=PUT`, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);


            })

            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={AddCategorySubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );

    return (
        <>
            <Button icon="pi pi-pencil " onClick={() => setVisible(true)}
                className="p-button-success border-0 p-button-icon-only" />
            <Dialog header="Store Details" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>

                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الاسم بالأنكليزية</label>
                    <InputText value={nameEn} onChange={(e) => setNameEn(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الاسم بالعربية</label>
                    <InputText value={nameAr} onChange={(e) => setNameAr(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الاسم بالكردية </label>
                    <InputText value={nameKu} onChange={(e) => setNameKu(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الوصف باللغة الإنجليزية</label>
                    <InputTextarea autoResize value={descEn} onChange={(e) => setDescEn(e.target.value)}
                        className="w-full mb-2" rows={5} cols={30} />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الوصف عربي</label>
                    <InputTextarea autoResize value={descAr} onChange={(e) => setDescAr(e.target.value)}
                        className="w-full mb-2" rows={5} cols={30} />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الوصف كردي </label>
                    <InputTextarea autoResize value={descKu} onChange={(e) => setDescKu(e.target.value)}
                        className="w-full mb-2" rows={5} cols={30} />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">Select Category</label>
                    <CategorySection category_section={category_section} setcategory_section={setcategory_section} />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">رقم الهاتف</label>
                    <InputText value={phone} onChange={(e) => setPhone(e.target.value)}
                        className="w-full mb-2" type="number" />
                </div>

                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">رقم الواتس اب</label>
                    <InputText value={number} onChange={(e) => setNumber(e.target.value)}
                        className="w-full mb-2" type="number" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">رابط فيسبوك</label>
                    <InputText value={link1} onChange={(e) => setLink1(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">رايط تيك توك</label>
                    <InputText value={link2} onChange={(e) => setLink2(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">الموقع</label>
                    <InputText value={location} onChange={(e) => setLocation(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">العنوان باللغة الكردية</label>
                    <InputText value={addressEn} onChange={(e) => setAddressEn(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">العنوان باللغة الكردية</label>
                    <InputText value={addressAr} onChange={(e) => setAddressAr(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">العنوان باللغة الكردية</label>
                    <InputText value={addressKu} onChange={(e) => setAddressKu(e.target.value)}
                        className="w-full mb-2" />
                </div>
                <div className="d-flex flex flex-column mb-2">
                    <label htmlFor="username">وقت العمل</label>
                    <div className="d-flex flex-wrap mt-2">
                        <div className="flex align-items-center mb-2">
                            <RadioButton inputId="always-open" name="time" value="1"
                                onChange={(e) => setOpen(e.value)} checked={open === '1'} />
                            <label className="ms-2">مفتوح دوماً</label>
                        </div>
                        <div className="flex align-items-center mb-2 ms-4">
                            <RadioButton inputId="ingredient2" name="time" value="0"
                                onChange={(e) => setOpen(e.value)} checked={open === '0'} />
                            <label className="ms-2">إغلاق</label>
                        </div>
                    </div>
                </div>
                {open === "0" &&
                    <div className="card flex justify-content-center">
                        <Sidebar visible={visible2} position="right" onHide={() => setVisible2(false)}>
                            <h4>أوقات عمل المتجر</h4>
                            <p>الرجاء إضافة الوقت بالتنسيق (24 ساعة:دقيقة)</p>
                            {days.map((item, index) => (
                                <div className=" row mb-3"  key={index}>
                                    <div className="col-sm-12 mb-2 text-capitalize fw-bold" key={index}> {item}:</div>
                                    <div className="col-sm-6" >
                                        <InputText key={index} value={g[index]} ref={(el) => (inputRefs.current[index] = el)}
                                            onChange={(e) => handleOpenTime(index)}
                                            className="w-full " placeholder="open time" />
                                    </div>
                                    <div className="col-sm-6" >
                                        <InputText key={index} value={g2[index]} ref={(el) => (inputRefs2.current[index] = el)}
                                            onChange={(e) => handleCloseTime(index)}
                                            className="w-full " placeholder="close time" />
                                    </div>


                                </div>

                            ))}
                        </Sidebar>
                        <Button label="Please add open time & close time" className="custom-btn2 " onClick={() => setVisible2(true)} />


                    </div>
                }
                <div className="d-flex gap-2 my-3">
                    <label htmlFor="username">إمكانية التوصيل</label>
                    <Checkbox
                        className="p-0"
                        checked={checked1}
                        onChange={handleChange1}

                    />
                </div>
                {checked1 === 1 &&
                    <div className="d-flex flex flex-column gap-2">
                        <label htmlFor="username">تكلفة التوصيل</label>
                        <InputText value={cost} onChange={(e) => setCost(e.target.value)}
                            className="w-full mb-2" />
                    </div>
                }
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">رفع صورة</label>
                    <ImageUploading value={images} onChange={onChange} dataURLKey="data_url">
                        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                            <div className="upload__image-wrapper position-relative mb-3">
                                <Button label="Choose" icon="pi pi-plus"
                                    className="btn-add w-100 d-block  py-2"
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    type="button" />
                                {imageList.length > 0 ? (
                                    imageList.map((image, index) => (
                                        <div key={index} className="image-item d-flex align-items-center">
                                            <img src={image['data_url']} alt="" width="100" />

                                            <div className="image-item__btn-wrapper">
                                                <Button icon="pi pi-trash" onClick={() => onImageRemove(index)}
                                                    className="p-button-text bg-danger text-white ms-3" />

                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <img src={`${STORAGE}${showImage}`} width="100%" />
                                )}
                            </div>
                        )}
                    </ImageUploading>
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">رفع لوغو</label>
                    <ImageUploading value={logo} onChange={onChangeLogo} dataURLKey="data_url">
                        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                            <div className="upload__image-wrapper position-relative mb-3">
                                <Button label="اختر" icon="pi pi-plus"
                                    className="btn-add w-100 d-block  py-2"
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    type="button" />
                                {imageList.length > 0 ? (
                                    imageList.map((image, index) => (
                                        <div key={index} className="image-item d-flex align-items-center">
                                            <img src={image['data_url']} alt="" width="100" />

                                            <div className="image-item__btn-wrapper">
                                                <Button icon="pi pi-trash" onClick={() => onImageRemove(index)}
                                                    className="p-button-text bg-danger text-white ms-3" />
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <img src={`${STORAGE}${showLogo}`} width="100%" />
                                )}
                            </div>
                        )}
                    </ImageUploading>
                </div>
            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
نجاح! تمت إضافة المعلومات بنجاح                </Alert>
            </Snackbar>
        </>
    )
}

export default UpdateStore