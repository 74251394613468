import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import ImageUploading from "react-images-uploading";
import { Alert, AlertTitle, CircularProgress, Snackbar } from "@mui/material";
import axios from "axios";
import { SLIDER, STORES} from "../../constants/url";
import { useNavigate } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown'; // Import Dropdown

const AddSlider = ({ reload, setReload }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [stores, setStores] = useState([]); // Updated to handle stores
  const [selectedStore, setSelectedStore] = useState(null); // Updated to selectedStore
  const [images, setImages] = useState([]);
  const [message, setMessage] = useState(false);
  const [erroralert, showErrorAlert] = useState([]);
  const [error, setError] = useState(false);
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };
  const handleCloseMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setMessage(false);
  };

  useEffect(() => {
    const fetchStores = async () => { // Updated to fetch stores
      try {
        const response = await axios.get(STORES, {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
          },
        });
        if (response.data && Array.isArray(response.data.data)) {
          setStores(response.data.data);
        } else {
          console.error("Expected an array of stores, but got:", response.data);
          setStores([]);
        }
      } catch (error) {
        console.error("Error fetching stores", error);
        setStores([]);
      }
    };
  
    fetchStores();
  }, [token]);
        
  const AddSliderSubmit = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("URL", url || ""); // Making URL optional

    if (selectedStore) {
      formData.append("store_id", selectedStore.id); // Updated to store_id
    }

    if (images.length != 0) {
      formData.append("image", images[0].file);
    }

    axios
      .post(SLIDER, formData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        setMessage(true);
        setTimeout(() => {
          setReload(!reload);
        }, 1500);
        setVisible(false);
        setError(false);
      })

      .catch((error) => {
        if (error.response && error.response.status === 401) {
          navigate("/login");
          localStorage.removeItem("token");
        } else if (error.response && error.response.data && error.response.data.errors) {
          showErrorAlert(error.response.data.errors);
        } else {
          console.error("Unexpected error structure:", error);
          showErrorAlert({ general: "An unexpected error occurred." });
        }
        setError(true);
      }).finally(() => {
          setLoading(false);
      });
  };
  const footerContent = (
    <div>
      <Button
        label="إغلاق"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
      />
      {loading ? (
        <Button className=" p-button-text custom-btn2">
          <CircularProgress style={{ color: "#fff" }} size={30} />
        </Button>
      ) : (
        <Button
          label="حفظ"
          icon="pi pi-check"
          onClick={AddSliderSubmit}
          className="p-button-text custom-btn2"
        />
      )}
    </div>
  );
  return (
    <div className="card flex justify-content-center">
      <Button
        label="سلايدر جديد"
        icon="pi pi-plus"
        onClick={() => setVisible(true)}
        className="custom-btn"
      />
      <Dialog
        header="تفاصيل السلايدر"
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
      >
        {error ? (
          <Alert severity="error" className="mb-4">
            <AlertTitle>خطأ</AlertTitle>
            <p>{erroralert.URL}</p>
            <p>{erroralert.image}</p>
          </Alert>
        ) : (
          ""
        )}
        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">رابط السلايدر</label>
          <InputText
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="w-full mb-2"
          />

          <label htmlFor="storeDropdown">المتجر</label>
          <Dropdown 
            id="storeDropdown"
            value={selectedStore} 
            options={stores} 
            onChange={(e) => setSelectedStore(e.value)}
            optionLabel="name" 
            placeholder="Select a Store"
            className="w-full mb-2"
          />


        </div>

        <div className="d-flex flex flex-column gap-2">
          <label htmlFor="username">رفع صورة</label>
          <ImageUploading
            value={images}
            onChange={onChange}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              <div className="upload__image-wrapper position-relative mb-3">
                <Button
                  label="اختر"
                  icon="pi pi-plus"
                  className="btn-add w-100 d-block  py-2"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                  type="button"
                />
                {imageList.map((image, index) => (
                  <div
                    key={index}
                    className="image-item d-flex align-items-center"
                  >
                    <img src={image["data_url"]} alt="" width="100" />

                    <div className="image-item__btn-wrapper">
                      <Button
                        icon="pi pi-trash"
                        onClick={() => onImageRemove(index)}
                        className="p-button-text bg-danger text-white ms-3"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
        </div>
      </Dialog>
      <Snackbar
        open={message}
        autoHideDuration={3000}
        onClose={handleCloseMessage}
        className="ic"
      >
        <Alert
          onClose={handleCloseMessage}
          severity="success"
          sx={{ width: "100%" }}
          style={{ background: "rgb(46, 125, 50)", color: "#fff" }}
        >
نجاح! تمت إضافة المعلومات بنجاح        </Alert>
      </Snackbar>
    </div>
  );
};

export default AddSlider;
