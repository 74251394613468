import React from "react";
import { Modal, Box, Typography, Button, IconButton, Divider } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import axios from "axios";
import { APPROVE_SUBSCRIPTION, DELETE_SUBSCRIPTION } from "../../constants/url";

const PlanDetailsModal = ({ open, handleClose, planDetails, token, setReload, reload }) => {
  const approveSubscription = async (id) => {
    try {
      const response = await axios.post(
        `${APPROVE_SUBSCRIPTION}/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setReload(!reload);
        alert("Subscription approved successfully");
        handleClose();
      }
    } catch (error) {
      console.error(error);
      alert("Failed to approve subscription");
    }
  };

  const deleteSubscription = async (id) => {
    try {
      const response = await axios.delete(`${DELETE_SUBSCRIPTION}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        setReload(!reload);
        alert("Subscription deleted successfully");
        handleClose();
      }
    } catch (error) {
      console.error(error);
      alert("Failed to delete subscription");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          تفاصيل الباقة
        </Typography>
        {planDetails ? (
          <Box>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>السعر:</strong> {planDetails.price}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>المدة:</strong> {planDetails.period}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>عدد المنتجات:</strong> {planDetails.products_number}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>عدد المنشورات:</strong> {planDetails.posts_number}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>عدد الفيديوهات:</strong> {planDetails.videos_number}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>الاشتراك يبدأ من:</strong> {planDetails.pivot.subscription_start}
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              <strong>الاشتراك ينتهي في:</strong> {planDetails.pivot.subscription_end}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
              {planDetails.pivot.is_valid != 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => approveSubscription(planDetails.pivot.id)}
                >
                  موافقة
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => deleteSubscription(planDetails.pivot.id)}
              >
                حذف
              </Button>
            </Box>
          </Box>
        ) : (
          <Typography sx={{ mt: 2 }}>No plan details available.</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default PlanDetailsModal;
