import { useState } from "react";
import { POLICY } from "../constants/url";
import axios from "axios";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { Link } from "react-router-dom";


const PolicyContent = () => {

    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const FetchPolicy = async () => {
        setLoading(true);
        await axios
            .get(POLICY, {
                headers: {
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                }
            })
            .catch((error) => {

            });

        setLoading(false);
    };
    useEffect(() => {
        FetchPolicy();
    }, []);
    return (
        <>
            <div className="head-content">
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-6   col-4'>
                            <div className='logo'>
                                <img src={require('../assets/img/logo-removebg-preview.png')} />
                            </div>
                        </div>
                        <div className='col-sm-6 col-8 text-end'>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                            <Link to="/terms-Conditions">Terms</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page">
                <div className="banner text-center">
                    <h1 className="fw-bold">Privacy Policy</h1>

                </div>
                <div className="container py-5">
                    {loading ? <Skeleton animation="wave" height={500} variant="rectangular" /> :
                        <div dangerouslySetInnerHTML={{ __html: ` <h5>${info.policy}</h5>` }} />

                    }
                </div>
            </div>
        </>
    )
}

export default PolicyContent