import { Dialog } from 'primereact/dialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button } from 'primereact/button';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { PRODUCTS, STORAGE } from '../../constants/url';
import CancelIcon from '@mui/icons-material/Cancel';
const Modal = (i) => {
    const [visible, setVisible] = useState(false);
    const [details, setDetils] = useState("")
    const [loading, setLoading] = useState(false);


    const FetchProductsDetails = async () => {
        setLoading(true);
        await axios
            .get(`${PRODUCTS}/${i.id}`, {
                headers: {
                    Accept: 'application/json',

                },
            })
            .then((response) => {
                if (response.status === 200) {
                    setDetils(response.data.data);

                }
            })
            .catch((error) => {
                if (error.response.status === 401 || error.response.status === 500) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
            });

        setLoading(false);
    };
    useEffect(() => {
        FetchProductsDetails();

    }, []);
    return (
        <>
            <Button severity="secondary" icon="pi pi-eye" onClick={() => setVisible(true)} id={i.id} />
            <Dialog header="Product Details" visible={visible}
                style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                <p>
                    <span className='fw-bold pe-2'>صور المنتج:</span>
                    <div className='d-flex pt-2'>
                        {details.images && details.images.map((item) => (
                            <div key={item.id} id={item.id}><img src={`${STORAGE}${item.url}`} /></div>
                        ))}
                    </div>
                </p>
            
                <p>
                    <span className='fw-bold pe-2'>SKU:</span>
                    {details.SKU}</p>
                <p>
                    <span className='fw-bold pe-2'>سياسة إرجاع الضمان:</span>
                    {details.warrantyReturningPolicy === true ?
                        <CheckCircleIcon color='success' /> : <CancelIcon color="error" />}</p>
                <p>
                    <span className='fw-bold pe-2'>منطقة حرة:</span>
                    {details.freeZone === true ?
                        <CheckCircleIcon color='success' /> : <CancelIcon color="error" />}</p>

                <div className='row'>
                    <div className='col-5'>
                        <div className='head p-2 text-center'>مقاس</div>
                    </div>
                    <div className='col-7'>
                        <div className='head p-2 text-center'>الألوان</div>
                    </div>

                </div>
                {details.variations && details.variations.map((item) => (
                    <div className='row' key={item.size.id} id={item.size.id}>
                        <div className='col-5 head2'>
                            {item.size.size}
                        </div>
                        <div className='col-7 head2'>
                            {item.size.color != null ?
                                item.colors.length && item.colors.map((color) => (
                                    `${color}, `
                                ))
                                : 'no colors'}
                        </div>
                    </div>
                ))}
              
            </Dialog>
        </>
    )
}

export default Modal