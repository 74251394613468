import { Button } from 'primereact/button';
import { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { IMAGES_POSTS } from '../../constants/url';
import { Alert, AlertTitle, CircularProgress, Snackbar } from '@mui/material';
import PostSection from '../PostSection';
import { Dialog } from 'primereact/dialog';
import axios from 'axios';

const AddImagesPost = ({ reload, setReload }) => {
    const [visible, setVisible] = useState(false);
    const [category_section, setcategory_section] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const token = localStorage.getItem('token');
    const [erroralert, showErrorAlert] = useState([]);
    const [error, setError] = useState(false);
    const onChange = (imageList, addUpdateIndex) => {
        setImages(imageList);
    };
    const handleCloseMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setMessage(false);
    };
    const AddPostImagesSubmit = () => {
        setLoading(true);
        const formData = new FormData();
        images.map((item, index) => (
            formData.append(`images[${index}]`, item.file)
        ))
        formData.append("post_id", category_section)
        axios
            .post(IMAGES_POSTS, formData, {
                headers: {
                    Accept: 'application/json',
                    Authorization: "Bearer " + token,
                },
            })
            .then((response) => {
                setMessage(true);
                setTimeout(() => {
                    setReload(!reload);
                }, 1500);
                setVisible(false);
                setError(false);

            })

            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/login');
                    localStorage.removeItem('token');
                }
                setError(true);
                showErrorAlert(error.response.data.errors);
                console.log(error.response.data.errors);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const footerContent = (
        <div>
            <Button label="إغلاق" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            {loading ? (
                <Button className=" p-button-text custom-btn2">
                    <CircularProgress style={{ color: '#fff' }} size={30} />
                </Button>
            ) : (
                <Button label="حفظ" icon="pi pi-check" onClick={AddPostImagesSubmit} className="p-button-text custom-btn2" />
            )}

        </div>
    );
    return (
        <div className="card flex justify-content-center">
            <Button label="صورة جديدة" icon="pi pi-plus" onClick={() => setVisible(true)} className="custom-btn" />
            <Dialog header="Image Details" visible={visible} style={{ width: '50vw' }}
                onHide={() => setVisible(false)} footer={footerContent}>
                {error ? (
                    <Alert severity="error" className="mb-4">
                        <AlertTitle>خطأ</AlertTitle>
                        <p>{erroralert.images}</p>
                        <p>{erroralert.post_id}</p>
                    </Alert>
                ) : (
                    ''
                )}
                <div className="d-flex flex flex-column gap-2 mt-4">
                    <label htmlFor="username">تحميل صورة بوست</label>
                    <ImageUploading multiple value={images} onChange={onChange} dataURLKey="data_url">
                        {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                            <div className="upload__image-wrapper position-relative mb-3">
                                <Button label="اختر" icon="pi pi-plus"
                                    className="btn-add w-100 d-block  py-2"
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    type="button" />
                                {
                                    imageList.map((image, index) => (
                                        <div key={index} className="image-item d-flex align-items-center">
                                            <img src={image['data_url']} alt="" width="100" />

                                            <div className="image-item__btn-wrapper">
                                                <Button icon="pi pi-trash" onClick={() => onImageRemove(index)}
                                                    className="p-button-text bg-danger text-white ms-3" />

                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        )}
                    </ImageUploading>

                    <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                        <Alert
                            onClose={handleCloseMessage}
                            severity="success"
                            sx={{ width: '100%' }}
                            style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                        >
                            نجاح! تمت إضافة المعلومات بنجاح
                        </Alert>
                    </Snackbar>
                </div>
                <div className="d-flex flex flex-column gap-2">
                    <label htmlFor="username">اختر بوست</label>
                    <PostSection category_section={category_section} setcategory_section={setcategory_section} />

                </div>

            </Dialog>
            <Snackbar open={message} autoHideDuration={3000} onClose={handleCloseMessage} className="ic">
                <Alert
                    onClose={handleCloseMessage}
                    severity="success"
                    sx={{ width: '100%' }}
                    style={{ background: 'rgb(46, 125, 50)', color: '#fff' }}
                >
                    نجاح! تمت إضافة المعلومات بنجاح
                </Alert>
            </Snackbar>
        </div>

    )
}

export default AddImagesPost